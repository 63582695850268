import './UserStoryMenu.css'

export default function UserStoryMenu({
                                          selectedUserStories,
                                          setSelectedUserStories,
                                          userStories,
                                          setSelectedArtefact,
                                      }) {

    const handleChange = (id) => {
        if (selectedUserStories.includes(id))
            setSelectedUserStories(selectedUserStories.filter(usId => usId !== id))
        else
            setSelectedUserStories(us => [...us, id])
    }

    const selectAll = () => {
        const allIds = userStories.map(us => us.id)
        setSelectedUserStories([...allIds])
    }

    const unselectAll = () => setSelectedUserStories([])

    function selectUserStory(us) {
        setSelectedArtefact({
            ...us,
            id: `US-${ us.id }`,
            usId: us.id,
            type: 'US',
        })
    }

    return (
        <div className="user-story-menu-container">
            <div className="buttons">
                <button onClick={ selectAll }>Select all</button>
                <button onClick={ unselectAll }>Unselect all</button>
            </div>
            <div className="user-story-list">
                { userStories.sort((a, b) => (a.uniqueUserStoryProjectId - b.uniqueUserStoryProjectId))
                    .map(us => (
                        <div key={ us.id } className="user-story-item" onClick={ () => selectUserStory(us) }>
                            <div>
                                <div className="user-story-name">User Story { us.uniqueUserStoryProjectId }</div>
                                <div className="user-story-content">{ us.userStory }</div>
                            </div>
                            <input
                                type="checkbox"
                                checked={ selectedUserStories.includes(us.id) }
                                onChange={ () => handleChange(us.id) }
                            />
                        </div>
                    )) }
            </div>
        </div>
    )
}
