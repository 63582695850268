import axios from "axios";
import {
    ADD_ONE_USERSTORY,
    DEL_USERSTORY_ERROR,
    DEL_USERSTORY_PENDING,
    DEL_USERSTORY_SUCCESS,
    GET_USERSTORIES_ERROR,
    GET_USERSTORIES_PENDING,
    GET_USERSTORIES_SUCCESS,
    SET_CURRENT_USERSTORY,
    UPDATE_ONE_USERSTORY,
    UPDATE_USERSTORY_ERROR,
    UPDATE_USERSTORY_PENDING,
    UPDATE_USERSTORY_SUCCESS,
} from './userStories.types'
import constants from '../../constants/index'
import store from "store";
import {authHeader} from "../../helpers/auth-header";

export const fetchUserStories = (projectID) => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        dispatch(fetchUserStoriesRequest());
        let userStoriesPromise = axios.get(constants().apiUrl + `/project/${projectID}/userstory`, requestConfig)
        userStoriesPromise.then(
            res => {
                dispatch(getAllUserStoriesSuccess(res.data.userStories))
            }
        ).catch(function (err) {
            dispatch(getAllUserStoriesError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const fetchUserStoriesRequest = () => ({
    type: GET_USERSTORIES_PENDING,
});

export const getAllUserStoriesSuccess = userStories => ({
    type: GET_USERSTORIES_SUCCESS,
    payload: {userStories}
});

export const getAllUserStoriesError = err => ({
    type: GET_USERSTORIES_ERROR,
    payload: {err}
});

export const addOneUserStory = userStory => ({
    type: ADD_ONE_USERSTORY,
    payload: {userStory}
});

export const updateOneUserStory = userStory => ({
    type: UPDATE_ONE_USERSTORY,
    payload: {userStory}
});

export const setCurrentUserStory = userStory => ({
    type: SET_CURRENT_USERSTORY,
    payload: {userStory}
});

export const delUserStoryPending = userStory => ({
    type: DEL_USERSTORY_PENDING,
    payload: {userStory}
});

export const delUserStorySuccess = userStory => ({
    type: DEL_USERSTORY_SUCCESS,
    payload: {userStory}
});

export const delUserStoryError = err => ({
    type: DEL_USERSTORY_ERROR,
    payload: {err}
});

export const updateUserStoryPending = userStory => ({
    type: UPDATE_USERSTORY_PENDING,
    payload: {userStory}
});

export const updateUserStorySuccess = userStory => ({
    type: UPDATE_USERSTORY_SUCCESS,
    payload: {userStory}
});

export const updateUserStoryError = err => ({
    type: UPDATE_USERSTORY_ERROR,
    payload: {err}
});

export const updateUserStoryRequest = (userStoryId, stateId, sprintId, projectId) => {
    const requestConfig = {
        headers: authHeader()
    };
    const state = store.getState();

    const valuesForUpdate = state.projects.current.userStories.userStoryList.find(userStory => userStory.userStoryId === Number(userStoryId))
    const values_new = {
        ...valuesForUpdate
    }

    // Remove unnecessary things before sending
    if ("userStoryId" in values_new) {
        delete values_new.userStoryId
    }
    if ("uniqueUsProjectId" in values_new) {
        delete values_new.uniqueUsProjectId
    }
    if ("creationDate" in values_new) {
        delete values_new.creationDate
    }
    if ("color" in values_new) {
        delete values_new.color
    }
    if ("themeName" in values_new) {
        delete values_new.themeName
    }
    if ("themeId" in values_new) {
        delete values_new.themeId
    }
    if ("firstname" in values_new) {
        delete values_new.firstname
    }
    if ("lastname" in values_new) {
        delete values_new.lastname
    }
    if ("userRoleName" in values_new) {
        delete values_new.userRoleName
    }
    if ("sprintName" in values_new) {
        delete values_new.sprintName
    }
    if ("stateName" in values_new) {
        delete values_new.stateName
    }
    values_new.stateId = stateId
    values_new.sprintId = sprintId


    return (dispatch) => {
        dispatch(updateUserStoryPending());
        let userStoriesPromise = axios.put(constants().apiUrl + '/project/' + projectId + '/userstory/' + userStoryId, values_new, requestConfig)
        userStoriesPromise.then(
            res => {
                dispatch(updateUserStorySuccess(res.data.userStory))
            }
        ).catch(function (err) {
            dispatch(updateUserStoryError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const delUserStoryRequest = (userStory, projectID) => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        dispatch(delUserStoryPending());
        let userStoriesPromise = axios.delete(constants().apiUrl + `/project/${projectID}/userstory/` + userStory.userStoryId, requestConfig)
        userStoriesPromise.then(
            res => {
                //dispatch(delUserStorySuccess(userStory))
            }
        ).catch(function (err) {
            dispatch(delUserStoryError(err))
            console.log('ERROR: ', err)
        })
    }
};