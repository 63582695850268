import React from 'react';
import FormControl from 'react-bootstrap/FormControl'
import {InputGroup} from "react-bootstrap";
import './FieldInput.css'

function FieldInput({fieldType, input, prepend, ariaLabel, meta: {touched, error}, label, ...props}) {
    return (
        <div className={`col-sm fieldInput ${fieldType ? fieldType : ''}`}>
            <label htmlFor="basic-url">{label}</label>
            <InputGroup>
                {prepend &&
                <InputGroup.Prepend id="basic-addon3">
                    <InputGroup.Text id="inputGroup-sizing-sm">{prepend}</InputGroup.Text>
                </InputGroup.Prepend>
                }
                <FormControl
                    aria-label={ariaLabel}
                    isInvalid={touched && error}
                    {...input}
                    {...props}
                />
                <FormControl.Feedback className={'error-feedback'}
                                      type="invalid">{error && touched ? error : ''}</FormControl.Feedback>
            </InputGroup>
        </div>
    )
}

export default FieldInput;