import React from 'react';
import {Form, FormControl, InputGroup} from 'react-bootstrap';
import './SelectInput.css'

function SelectInput({input, prepend, meta, type, placeholder, options, label, meta: {touched, error}}) {
    return (
        <div className="col-sm">
            <Form.Label>{label}</Form.Label>
            <InputGroup className="mb-3">
                {prepend &&
                <InputGroup.Prepend id="basic-addon3">
                    <InputGroup.Text id="inputGroup-sizing-sm">{prepend}</InputGroup.Text>
                </InputGroup.Prepend>
                }
                <Form.Control {...input} as="select" isInvalid={touched && error}>
                    {
                        options.map((item) => {
                            return <option key={item.id} value={item.id} href="#">{item.name}</option>
                        })
                    }
                    <option value="">Select</option>
                </Form.Control>
                <FormControl.Feedback className={'error-feedback'}
                                      type="invalid">{error && touched ? error : ''}</FormControl.Feedback>
            </InputGroup>
        </div>
    )
}

export default SelectInput;