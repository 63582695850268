import {Trash, Sunglasses} from "react-bootstrap-icons";
import Button from "components/form/buttons/SubmitButton";
import React from "react";
import store from "store";
import "components/project/projectSettings/projectSettingsCards/collaboratorSettingsCard/CollaboratorSettingsCard.css"
import {delCollaborator} from 'model/collaborators/collaborators.actions'

function CollaboratorListItem({user, ...props}) {
    let projectId = props.projectId
    let userId = user.userId

    return (
        <div className="collaboratorListItem">
            <div className="collaboratorName">{user.firstname + ", " + user.lastname}</div>
            {!user.owner && <Button
                styleClass="btn-primary-custom-btn-naked deleteCollaboratorButton"
                name={<Trash/>}
                onClick={() => onDelete(projectId, userId)}
            />}
            {user.owner && <Button
                styleClass="btn-primary-custom-btn-naked deleteCollaboratorButton"
                name={<Sunglasses/>}
            />}
        </div>
    )
}

const onDelete = (projectId, userId) => {
    store.dispatch(delCollaborator(projectId, userId))
}

export default CollaboratorListItem;