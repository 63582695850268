import React, {useEffect, useState} from "react"
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import store from "store";

import FieldInput from "components/form/fieldInput/FieldInput";
import FieldDatePicker from "components/form/datePicker/FieldDatePicker";
import {maxLength100, minLength3, required} from "validation/InputValidation";
import FormCard from "components/form/formCard/FormCard";
import Button from "components/form/buttons/SubmitButton";
import {
    cleanGeneralSettings,
    fetchGeneralSettings,
    updateGeneralSettings
} from "model/generalProjectSettings/generalProjectSettings.actions";
import DateFormatter from "helpers/dateFormatter";
import {addProject} from "model/projects/project.actions";

let projectId;

const mapStateToProps = state => {
    return {
        initialValues: state.projects.current.generalProjectSettings.generalProjectSettings ? {
            ...state.projects.current.generalProjectSettings.generalProjectSettings,
            startDate: DateFormatter(state.projects.current.generalProjectSettings.generalProjectSettings.startDate)
        } : null,
        loading: state.projects.current.generalProjectSettings.pending,
        err: state.projects.current.generalProjectSettings.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchGeneralSettings: (projectId) => dispatch(fetchGeneralSettings(projectId)),
        cleanCurrentProject: () => dispatch(cleanGeneralSettings())
    }
}

function GeneralSettingsCard({handleSubmit, ...props}) {
    projectId = props.projectId
    const [isCreate, setIsCreate] = useState(props.isCreate);

    useEffect(() => {
        if (!isCreate) {
            props.fetchGeneralSettings(projectId)
        }
        return props.cleanCurrentProject
    }, [])

    return (
        <FormCard title="General Settings" width="30vw" loading={props.loading} error={props.err}>
            <form id="generalSettings" onSubmit={handleSubmit}>
                <Field name="name"
                       label="Project name"
                       type="text"
                       component={FieldInput}
                       placeholder="Project name"
                       aria-label="Recipient's username"
                       aria-describedby="basic-addon2"
                       validate={[required, minLength3, maxLength100]}
                />
                <Field name="startDate"
                       type="text"
                       label="Start date"
                       component={FieldDatePicker}
                       placeholder="Start date"
                       aria-label="Recipient's username"
                       aria-describedby="basic-addon2"
                       validate={[required]}
                />
                <Field name="description"
                       as="textarea"
                       component={FieldInput}
                       placeholder="Project description"
                       ariaLabel="With textarea"
                       validate={[minLength3, maxLength100]}
                       label="Project description"
                />
                <Button
                    type="submit"
                    styleClass="btn-primary-custom-btn-primary"
                    name="Save"
                />
            </form>
        </FormCard>
    )
}

const onSubmit = (values, dispatch, state) => {
    if (!state.isCreate) {
        store.dispatch(updateGeneralSettings(projectId, values))
    } else {
        store.dispatch(addProject(values))
    }
}

GeneralSettingsCard = reduxForm({
    form: 'generalSettingsForm',
    enableReinitialize: true,
    onSubmit
})(GeneralSettingsCard)

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettingsCard)