import axios from "axios";
import {
    ADD_SPRINTS_ERROR,
    ADD_SPRINTS_PENDING,
    ADD_SPRINTS_SUCCESS,
    CLEAN_CURRENT_SPRINTS_PROJECT,
    DEL_SPRINTS_ERROR,
    DEL_SPRINTS_PENDING,
    DEL_SPRINTS_SUCCESS,
    GET_SPRINTS_ERROR,
    GET_SPRINTS_PENDING,
    GET_SPRINTS_SUCCESS,
    UPDATE_SPRINTS_ERROR,
    UPDATE_SPRINTS_PENDING,
    UPDATE_SPRINTS_SUCCESS
} from './sprints.types'
import constants from '../../constants/index'
import {authHeader} from "helpers/auth-header";
import store from "store";
import DateFormatter from "helpers/dateFormatter";

export const fetchSprints = (projectId) => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        dispatch(fetchSprintsRequest());
        let sprintsPromise = axios.get(constants().apiUrl + '/project/' + projectId + '/sprint', requestConfig)
        sprintsPromise.then(
            res => {
                dispatch(getAllSprintsSuccess(res.data.sprints))
            }
        ).catch(function (err) {
            dispatch(getAllSprintsError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const fetchSprintsRequest = () => ({
    type: GET_SPRINTS_PENDING,
});

export const getAllSprintsSuccess = sprints => ({
    type: GET_SPRINTS_SUCCESS,
    payload: {sprints}
});

export const getAllSprintsError = err => ({
    type: GET_SPRINTS_ERROR,
    payload: err
});


export const addSprint = (projectId, values) => {
    const requestConfig = {
        headers: authHeader()
    };

    if (values.sprintId) {
        delete values.sprintId
    }

    let valuesNew = {
        ...values,
        sprintNumber: getNextBiggestSprintNumberFromCurrent()
    }

    valuesNew.startDate = DateFormatter(valuesNew.startDate)
    valuesNew.endDate = DateFormatter(valuesNew.endDate)

    return (dispatch) => {

        dispatch(addSprintsRequest());
        let sprintsPromise = axios.post(constants().apiUrl + '/project/' + projectId + '/sprint', valuesNew, requestConfig)
        sprintsPromise.then(
            res => {
                dispatch(addSprintsSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(addSprintsError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const addSprintsRequest = () => ({
    type: ADD_SPRINTS_PENDING,
});

export const addSprintsSuccess = data => ({
    type: ADD_SPRINTS_SUCCESS,
    payload: data
});

export const addSprintsError = err => ({
    type: ADD_SPRINTS_ERROR,
    payload: err
});

export const delSprint = (projectId, sprintId) => {
    const requestConfig = {
        headers: authHeader()
    };

    return (dispatch) => {

        dispatch(delSprintsRequest());
        let sprintsPromise = axios.delete(constants().apiUrl + '/project/' + projectId + '/sprint/' + sprintId, requestConfig)
        sprintsPromise.then(
            res => {
                dispatch(delSprintsSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(delSprintsError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const delSprintsRequest = () => ({
    type: DEL_SPRINTS_PENDING,
});

export const delSprintsSuccess = data => ({
    type: DEL_SPRINTS_SUCCESS,
    payload: data
});

export const delSprintsError = err => ({
    type: DEL_SPRINTS_ERROR,
    payload: err
});

export const updateSprint = (projectId, sprintId, values) => {
    const requestConfig = {
        headers: authHeader()
    };

    let valuesNew = {...values}

    if (valuesNew.sprintId) {
        delete valuesNew.sprintId
    }

    valuesNew = {
        ...valuesNew,
        sprintNumber: getNextBiggestSprintNumberFromCurrent()
    }

    valuesNew.startDate = DateFormatter(valuesNew.startDate)
    valuesNew.endDate = DateFormatter(valuesNew.endDate)

    return (dispatch) => {

        dispatch(updateSprintsRequest());
        let sprintsPromise = axios.put(constants().apiUrl + '/project/' + projectId + '/sprint/' + sprintId, valuesNew, requestConfig)
        sprintsPromise.then(
            res => {
                dispatch(updateSprintsSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(updateSprintsError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const updateSprintsRequest = () => ({
    type: UPDATE_SPRINTS_PENDING,
});

export const updateSprintsSuccess = data => ({
    type: UPDATE_SPRINTS_SUCCESS,
    payload: data
});

export const updateSprintsError = err => ({
    type: UPDATE_SPRINTS_ERROR,
    payload: err
});

export const cleanSprints = () => ({
    type: CLEAN_CURRENT_SPRINTS_PROJECT
})

const getNextBiggestSprintNumberFromCurrent = () => {
    let state = store.getState();
    let biggestNumber = 0;
    state.projects.current.sprints.sprintsList.map(sprint => {
        if (sprint.sprintNumber > biggestNumber) {
            biggestNumber = sprint.sprintNumber
        }
    })
    return biggestNumber + 1
}