export const REGISTRATION_SUBMIT_ERROR = 'REGISTRATION_SUBMIT_ERROR';
export const REGISTRATION_SUBMIT_PENDING = 'REGISTRATION_SUBMIT_PENDING';
export const REGISTRATION_SUBMIT_SUCCESS = 'REGISTRATION_SUBMIT_SUCCESS';

export const LOGIN_SUBMIT_ERROR = 'LOGIN_SUBMIT_ERROR';
export const LOGIN_SUBMIT_PENDING = 'LOGIN_SUBMIT_PENDING';
export const LOGIN_SUBMIT_SUCCESS = 'LOGIN_SUBMIT_SUCCESS';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_PENDING = 'GET_USER_PENDING';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const LOGOUT = 'USER_LOGOUT';