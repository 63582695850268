import {CLEAN_CURRENT_FILTERCRITERIA_PROJECTS, FILTERINGFORM_SUBMIT_PENDING} from "./filterCriteria.types";

export const sendFilteringForm = (values) => {
    return (dispatch) => {
        dispatch(sendFilteringFormPending(values));
    }
};

export const sendFilteringFormPending = (values) => ({
    type: FILTERINGFORM_SUBMIT_PENDING,
    payload: values
});

export const cleanFilterCriteria = () => ({
    type: CLEAN_CURRENT_FILTERCRITERIA_PROJECTS
})