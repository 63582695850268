import './SelectedUserStory.css'
import { useEffect, useState } from 'react'

export default function SelectedUserStory({
                                              userStory,
                                              selectedArtefact,
                                              entities,
                                              entityColors,
                                              setSelectedArtefact,
                                          }) {
    const [roleChunks, setRoleChunks] = useState([])
    const [endsChunks, setEndsChunks] = useState([])
    const [meansChunks, setMeansChunks] = useState([])

    useEffect(() => {
        if (!userStory.userStory) return
        let i = 2
        const content = userStory.userStory
        const [_, values] = (content.startsWith('As an')) ?
            content.split('As an ') : content.split('As a ')
        const [actor, endsAndMeans] = values.split(', I want ', 2)
        const [ends, means] = endsAndMeans.split(' so that ', 2)
        const tempRoleChunks = []
        for (const chunk of actor.split(' ')) {
            tempRoleChunks.push({ index: i, chunk })
            if (chunk.includes('\'')) i++
            if (chunk.includes(',')) i++
            i++
        }
        setRoleChunks(tempRoleChunks)
        i = i + 3
        const tempEndsChunks = []
        for (const chunk of ends.split(' ')) {
            tempEndsChunks.push({ index: i, chunk })
            if (chunk.includes('\'')) i++
            if (chunk.includes(',')) i++
            i++
        }
        setEndsChunks(tempEndsChunks)
        i = i + 2
        const tempMeansChunks = []
        for (const chunk of means.split(' ')) {
            tempMeansChunks.push({ index: i, chunk })
            if (chunk.includes('\'')) i++
            if (chunk.includes(',')) i++
            i++
        }
        setMeansChunks(tempMeansChunks)
    }, [userStory])

    function isSelected(index) {
        if (selectedArtefact && selectedArtefact.type === 'US') return false
        const instance = selectedArtefact.instances.find(instance => instance.mainArtefactId === userStory.id)
        if (!instance) return false
        return instance.position.includes(index)
    }

    function isEntity(index) {
        const entity = getEntity(index)
        return !!entity
    }

    function getEntity(index) {
        const usId = userStory.usId ? userStory.usId : userStory.id
        return entities.find(entity => {
            const instances = entity.instances.filter(instance => instance.mainArtefactId === usId && instance.position.includes(index))
            return (instances.length > 0)
        })
    }

    function getEntityColor(index) {
        const entity = getEntity(index)
        if (entity) return entityColors[entity.type]
        return 'none'
    }

    return (
        <>
            <div className="details-selected-user-story">
                <div className="name">role</div>
                <div className="value">
                    {
                        roleChunks.map((chunk, i) => (
                            <span
                                key={ i }
                                className={ `${ isSelected(chunk.index) ? 'selected' : 'underline' }` }
                                style={ {
                                    borderBottom: isEntity(chunk.index) ? 'solid 2px' : 'none',
                                    borderBottomColor: getEntityColor(chunk.index),
                                    cursor: isEntity(chunk.index) ? 'pointer' : 'auto',
                                } }
                                onClick={ () => setSelectedArtefact(getEntity(chunk.index)) }
                            >{ chunk.chunk }</span>
                        ))
                    }
                </div>
                <div className="name">ends</div>
                <div className="value">
                    {
                        endsChunks.map(chunk => (
                            <span
                                className={ `${ isSelected(chunk.index) ? 'selected' : 'underline' }` }
                                style={ {
                                    borderBottom: isEntity(chunk.index) ? 'solid 2px' : 'none',
                                    borderBottomColor: getEntityColor(chunk.index),
                                    cursor: isEntity(chunk.index) ? 'pointer' : 'auto',
                                } }
                                onClick={ () => setSelectedArtefact(getEntity(chunk.index)) }
                            >{ chunk.chunk }</span>
                        ))
                    }
                </div>
                <div className="name">means</div>
                <div className="value">
                    {
                        meansChunks.map(chunk => (
                            <span
                                className={ `${ isSelected(chunk.index) ? 'selected' : 'underline' }` }
                                style={ {
                                    borderBottom: isEntity(chunk.index) ? 'solid 2px' : 'none',
                                    borderBottomColor: getEntityColor(chunk.index),
                                    cursor: isEntity(chunk.index) ? 'pointer' : 'auto',
                                } }
                                onClick={ () => setSelectedArtefact(getEntity(chunk.index)) }
                            >{ chunk.chunk }</span>
                        ))
                    }
                </div>
            </div>
        </>
    )
}