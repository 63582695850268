export const GET_THEMES_SUCCESS = 'GET_THEMES_SUCCESS';
export const GET_THEMES_PENDING = 'GET_THEMES_PENDING';
export const GET_THEMES_ERROR = 'GET_THEMES_ERROR';

export const ADD_THEMES_SUCCESS = 'ADD_THEMES_SUCCESS';
export const ADD_THEMES_PENDING = 'ADD_THEMES_PENDING';
export const ADD_THEMES_ERROR = 'ADD_THEMES_ERROR';

export const DEL_THEMES_SUCCESS = 'DEL_THEMES_SUCCESS';
export const DEL_THEMES_PENDING = 'DEL_THEMES_PENDING';
export const DEL_THEMES_ERROR = 'DEL_THEMES_ERROR';

export const UPDATE_THEMES_SUCCESS = 'UPDATE_THEMES_SUCCESS';
export const UPDATE_THEMES_PENDING = 'UPDATE_THEMES_PENDING';
export const UPDATE_THEMES_ERROR = 'UPDATE_THEMES_ERROR';

export const CLEAN_CURRENT_THEMES_PROJECT = 'CLEAN_CURRENT_THEMES_PROJECT';