import axios from "axios";
import {
    USERSTORYFORM_SUBMIT_ERROR,
    USERSTORYFORM_SUBMIT_PENDING,
    USERSTORYFORM_SUBMIT_SUCCESS
} from './userStoryForm.types';
import constants from "../../../constants";
import {updateOneUserStory} from "../../userStories/userStories.actions";
import {authHeader} from "helpers/auth-header";


export const sendUserStoryForm = (values, projectId) => {
    const values_new = {
        ...values,
        stateId: null,
        sprintId: null
    }

    const requestConfig = {
        headers: authHeader()
    };

    return (dispatch) => {
        dispatch(sendUserStoryFormPending(values));
        let userStoriesPromise = axios.post(constants().apiUrl + '/project/' + projectId + '/userstory', values_new, requestConfig)
        userStoriesPromise.then(
            res => {
                dispatch(sendUserStoryFormSuccess());
                //dispatch(addOneUserStory(res.data.userStory));
            }
        ).catch(function (err) {
            dispatch(sendUserStoryFormError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const sendUserStoryFormPending = (values) => ({
    type: USERSTORYFORM_SUBMIT_PENDING,
    payload: values
});

export const sendUserStoryFormSuccess = () => ({
    type: USERSTORYFORM_SUBMIT_SUCCESS,
});

export const sendUserStoryFormError = err => ({
    type: USERSTORYFORM_SUBMIT_ERROR,
    payload: {err}
});

export const sendUpdateUserStory = (values) => {

    // Remove unnecessary things
    const values_new = {
        ...values
    }
    // Remove unnecessary things before sending
    if ("uniqueUsProjectId" in values_new) {
        delete values_new.uniqueUsProjectId
    }
    if ("userStoryId" in values_new) {
        delete values_new.userStoryId
    }
    if ("creationDate" in values_new) {
        delete values_new.creationDate
    }
    if ("color" in values_new) {
        delete values_new.color
    }
    if ("themeName" in values_new) {
        delete values_new.themeName
    }
    if ("firstname" in values_new) {
        delete values_new.firstname
    }
    if ("lastname" in values_new) {
        delete values_new.lastname
    }
    if ("userRoleName" in values_new) {
        delete values_new.userRoleName
    }
    if ("sprintName" in values_new) {
        delete values_new.sprintName
    }
    if ("stateName" in values_new) {
        delete values_new.stateName
    }

    return (dispatch) => {
        const requestConfig = {
            headers: authHeader()
        };
        dispatch(sendUserStoryFormPending(values));
        let userStoriesPromise = axios.put(constants().apiUrl + '/project/' + values_new.projectId + '/userstory/' + values.userStoryId, values_new, requestConfig)
        userStoriesPromise.then(
            res => {
                dispatch(sendUserStoryFormSuccess());
                dispatch(updateOneUserStory(res.data.userStory));
            }
        ).catch(function (err) {
            dispatch(sendUserStoryFormError(err))
            console.log('ERROR: ', err)
        })
    }
};