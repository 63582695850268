import {
    ADD_ONE_USERSTORY,
    DEL_USERSTORY_ERROR,
    DEL_USERSTORY_PENDING,
    DEL_USERSTORY_SUCCESS,
    GET_USERSTORIES_ERROR,
    GET_USERSTORIES_PENDING,
    GET_USERSTORIES_SUCCESS,
    SET_CURRENT_USERSTORY,
    UPDATE_ONE_USERSTORY,
    UPDATE_USERSTORY_ERROR,
    UPDATE_USERSTORY_PENDING,
    UPDATE_USERSTORY_SUCCESS,
} from './userStories.types';

const INITIAL_STATE = {
    userStoryList: [],
    pending: false,
    current: null,
    error: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USERSTORIES_PENDING:
            return {
                ...state,
                pending: true
            };
        case GET_USERSTORIES_SUCCESS:
            return {
                ...state,
                pending: false,
                userStoryList: action.payload.userStories
            };
        case GET_USERSTORIES_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.err
            };
        case ADD_ONE_USERSTORY:
            return {
                ...state,
                userStoryList: state.userStoryList.concat(action.payload.userStory)
            };
        case SET_CURRENT_USERSTORY:
            return {
                ...state,
                current: action.payload.userStory
            }
        case UPDATE_ONE_USERSTORY:
            return {
                ...state,
                userStoryList: state.userStoryList.map((item, number) => {
                    if (item.userStoryId === action.payload.userStory.userStoryId) {
                        return action.payload.userStory
                    }
                    return item
                })
            }
        case DEL_USERSTORY_PENDING:
            // TODO: Loading specific for userstory not for whole page
            return {
                ...state,
                pending: false
            }
        case DEL_USERSTORY_SUCCESS:
            return {
                ...state,
                pending: false,
                userStoryList: state.userStoryList.filter(userStory =>
                    userStory.userStoryId !== action.payload.userStory.userStoryId
                )
            }
        case DEL_USERSTORY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.err
            }
        case UPDATE_USERSTORY_PENDING:
            return {
                ...state,
                pending: false
            }
        case UPDATE_USERSTORY_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                userStoryList: state.userStoryList.map((item) => {
                    if (item.userStoryId === action.payload.userStory.userStoryId) {
                        return action.payload.userStory
                    }
                    return item
                })
            }
        case UPDATE_USERSTORY_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.err
            }

        default:
            return state;
    }
};

export default reducer;