export const GET_SPRINTS_SUCCESS = 'GET_SPRINTS_SUCCESS';
export const GET_SPRINTS_PENDING = 'GET_SPRINTS_PENDING';
export const GET_SPRINTS_ERROR = 'GET_SPRINTS_ERROR';

export const DEL_SPRINTS_SUCCESS = 'DEL_SPRINTS_SUCCESS';
export const DEL_SPRINTS_PENDING = 'DEL_SPRINTS_PENDING';
export const DEL_SPRINTS_ERROR = 'DEL_SPRINTS_ERROR';

export const ADD_SPRINTS_SUCCESS = 'ADD_SPRINTS_SUCCESS';
export const ADD_SPRINTS_PENDING = 'ADD_SPRINTS_PENDING';
export const ADD_SPRINTS_ERROR = 'ADD_SPRINTS_ERROR';

export const UPDATE_SPRINTS_SUCCESS = 'UPDATE_SPRINTS_SUCCESS';
export const UPDATE_SPRINTS_PENDING = 'UPDATE_SPRINTS_PENDING';
export const UPDATE_SPRINTS_ERROR = 'UPDATE_SPRINTS_ERROR';

export const CLEAN_CURRENT_SPRINTS_PROJECT = 'CLEAN_CURRENT_SPRINTS_PROJECT';

