import store from "store";
import {io} from "socket.io-client"
import {
    closeConnectionToWebSocketServer,
    connectedToWebSocketServer,
    connectToWebSocketServer,
    errorConnectWebSocketServer,
    updateSocialInformation
} from "model/webSocketReceiver/webSocketReceiver.actions";
import constants from '../constants/index'
import {addOneUserStory, delUserStorySuccess, updateOneUserStory} from "model/userStories/userStories.actions";

const socketConfiguration = {
    transports: ['websocket'],
    reconnection: true,
    path: constants().env === "dev" ? "/realtime/" : "/api/v1/realtime/"
}

class WsServerConnector {
    constructor(url, projectId) {
        this.url = url
        this.projectId = projectId
        this.socket = io(url, socketConfiguration)
        this.initialize()
        this.socket.connect()
        store.dispatch(connectedToWebSocketServer(url))
    }

    initialize() {
        let self = this;
        this.socket.on("disconnect", function (e) {
            store.dispatch(closeConnectionToWebSocketServer())
        });

        this.socket.on("connect", function () {
            store.dispatch(connectedToWebSocketServer(self.url))
        });

        this.socket.on("social", function (data) {
            store.dispatch(updateSocialInformation(data))
        })

        this.socket.on("update-us", function (data) {
            let userStory = data
            store.dispatch(updateOneUserStory(userStory))
        })

        this.socket.on("delete-us", function (data) {
            let userStory = data
            store.dispatch(delUserStorySuccess(userStory))
        })

        this.socket.on("create-us", function (data) {
            let userStory = data
            store.dispatch(addOneUserStory(userStory))
        })

        this.socket.on("connect_error", (err) => this.onErrorConnection(err))
    }

    closeConnection() {
        this.socket.disconnect()
    }

    tryReconnect() {
        store.dispatch(connectToWebSocketServer())
        setTimeout(() => {
            this.socket = io(this.url, socketConfiguration)
            this.initialize()
        }, 1000)
    }

    onErrorConnection(err) {
        store.dispatch(errorConnectWebSocketServer(err))
        console.error('Socket encountered error: ', err.message, 'Closing socket');
        this.closeConnection()
        this.tryReconnect()
    }
}

export default WsServerConnector;