import { useEffect, useRef } from 'react'
import * as d3 from 'd3'
import { dragEnded, dragged, dragStarted, handleZoom } from '../../d3Util'
import './ClassDiagramView.css'

export default function ClassDiagramView({ entityColors, entities, selectedUserStories, setSelectedArtefact }) {
    const svgRef = useRef()
    const width = '100%'
    const height = '100%'
    const viewBoxWidth = 1080
    const viewBoxHeight = 540
    const classHeight = 30
    const classWidth = 60

    useEffect(() => {
        // const filteredEntities = entities.filter(entity => {
        //     if (entity.type === 'ACTOR' || entity.type === 'CLASS' || entity.type === 'RELATIONSHIP') {
        //         if (selectedUserStories.length === 0) return true
        //         const ids = entity.instances.map(i => i.mainArtefactId)
        //         return ids.filter(id => selectedUserStories.includes(id)).length > 0
        //     } else {
        //         return false
        //     }
        // })
        const nodes = entities.filter(entity => entity.type === 'ACTOR' || entity.type === 'CLASS')
        const links = entities.filter(entity => entity.type === 'RELATIONSHIP')
            .flatMap(entity => entity.links.flatMap(link => ([
                {
                    ...entity,
                    source: link.from,
                    target: link.to,
                },
            ])))
        buildGraph(nodes, links)
    }, [])

    function buildGraph(nodes, links) {
        function ticked() {
            link
                .attr('x1', d => d.source.x)
                .attr('y1', d => d.source.y)
                .attr('x2', d => d.target.x)
                .attr('y2', d => d.target.y)

            node
                .attr('x', d => d.x - (classWidth / 2))
                .attr('y', d => d.y - (classHeight / 2))

            text
                .attr('x', d => d.x - (classWidth / 2))
                .attr('y', d => d.y - (classHeight / 2))
        }

        const simulation = d3.forceSimulation(nodes)
            .force('link', d3.forceLink(links).id(d => d.id).distance(160))
            .force('charge', d3.forceManyBody())
            .force('center', d3.forceCenter(viewBoxWidth / 2, viewBoxHeight / 2))
            .on('tick', ticked)

        d3.select(svgRef.current)
            .selectAll('*')
            .remove()

        const svg = d3.select(svgRef.current)
            .attr('width', width)
            .attr('height', height)
            .attr('viewBox', [0, 0, viewBoxWidth, viewBoxHeight])
            .on('contextmenu', (e) => e.preventDefault())
            .call(d3.zoom()
                .on('zoom', handleZoom))

        const link = svg.append('g')
            .attr('stroke', '#656565')
            .attr('stroke-opacity', 0.6)
            .selectAll('line')
            .data(links)
            .join('line')
            .attr('stroke-width', 1)

        const nodeGroup = svg.append('g')
            .selectAll('g')
            .data(nodes)
            .enter()
            .append('g')
            .on('click', (e, node) => setSelectedArtefact(node))
            .call(d3.drag()
                .on('start', (e) => dragStarted(e, simulation))
                .on('drag', dragged)
                .on('end', (e) => dragEnded(e, simulation)))

        const node = nodeGroup
            .append('rect')
            .attr('height', classHeight)
            .attr('width', classWidth)
            .attr('stroke', d => entityColors[d.type])
            .attr('stroke-width', 2)
            .attr('fill', '#fff')

        const text = nodeGroup
            .append('text')
            .text(d => d.name)
            .attr('font-size', '10px')
            .attr('font-weight', 'lighter')
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'middle')
            .attr('dx', 30)
            .attr('dy', 15)
            .attr('stroke', '#000')
    }

    return (
        <svg ref={ svgRef } className="class-diagram"></svg>
    )
}