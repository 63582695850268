import { useEffect } from 'react'

export default function ClassDropdown({ classes, selectedClass, setSelectedClass }) {

    useEffect(() => {
        if (!selectedClass) {
            setSelectedClass(classes[0].id)
        }
    }, [])

    const selectClass = (e) => {
        setSelectedClass(e.target.value)
    }

    return (
        <div className="modal-input-group">
            <label htmlFor="class-select">Select class:</label>
            <select
                name="class-select"
                id="class-select"
                onChange={ (e) => selectClass(e) }
            >
                {
                    classes.map((c, key) => (
                        <option value={ c.id } key={ key } selected={ c.id === selectedClass?.id }>{ c.name }</option>
                    ))
                }
            </select>
        </div>
    )
};