import './ArtefactDetails.css'
import React, { useState } from 'react'
import ModalGroup from '../../modals/ModalGroup'

export default function ArtefactDetails({ classes, artefact, selectedUserStory }) {
    const [showUpdateInstanceModal, setShowUpdateInstanceModal] = useState(false)
    const [showDeleteInstanceModal, setShowDeleteInstanceModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showAddClassModal, setShowAddClassModal] = useState(false)
    const [showAddAttributeModal, setShowAddAttributeModal] = useState(false)
    const [showAddRelationshipModal, setShowAddRelationshipModal] = useState(false)

    const getTypeName = () => {
        switch (artefact.type) {
            case 'CLASS':
                return 'Class'
            case 'ACTOR':
                return 'Actor'
            case 'ATTRIBUTE':
                return 'Attribute'
            case 'OPERATION':
                return 'Operation'
            case 'RELATIONSHIP':
                return 'Relationship'
            default:
                return 'User Story'
        }
    }

    const getName = () => {
        switch (artefact.type) {
            case 'US':
                return `US${ artefact.uniqueUserStoryProjectId }`
            default:
                return artefact.name
        }
    }

    const getId = () => {
        switch (artefact.type) {
            case 'US':
                return `US${ artefact.uniqueUserStoryProjectId }`
            default:
                return artefact.id
        }
    }

    return (
        <>
            <ModalGroup
                classes={ classes }
                artefact={ artefact }
                userStory={ selectedUserStory }
                showDeleteInstanceModal={ showDeleteInstanceModal }
                setShowDeleteInstanceModal={ setShowDeleteInstanceModal }
                showUpdateInstanceModal={ showUpdateInstanceModal }
                setShowUpdateInstanceModal={ setShowUpdateInstanceModal }
                showDeleteModal={ showDeleteModal }
                setShowDeleteModal={ setShowDeleteModal }
                showAddClassModal={ showAddClassModal }
                setShowAddClassModal={ setShowAddClassModal }
                showAddAttributeModal={ showAddAttributeModal }
                setShowAddAttributeModal={ setShowAddAttributeModal }
                showAddRelationshipModal={ showAddRelationshipModal }
                setShowAddRelationshipModal={ setShowAddRelationshipModal }
            />
            <div className="details-selected-artefact">
                <div className="name">name</div>
                <div className="value">{ getName() }</div>
                <div className="name">artefact type</div>
                <div className="value">{ getTypeName() }</div>
                <div className="name">id</div>
                <div className="value">{ getId() }</div>
            </div>

            <div>
                {/*{ (artefact.type !== 'US') &&*/ }
                {/*    <div className="artefact-actions">*/ }
                {/*        <div className="action delete-instance-action" onClick={ () => setShowDeleteInstanceModal(true) }>*/ }
                {/*            Delete Instance*/ }
                {/*        </div>*/ }
                {/*        <div className="action update-instance-action" onClick={ () => setShowUpdateInstanceModal(true) }>*/ }
                {/*            Update Instance*/ }
                {/*        </div>*/ }
                {/*    </div>*/ }
                {/*}*/ }
                <div className="artefact-actions">
                    { (artefact.type !== 'US') &&
                        <div className="action delete-artefact-action" onClick={ () => setShowDeleteModal(true) }>Delete
                            Artefact
                        </div>
                    }
                    { (artefact.type === 'CLASS' || artefact.type === 'ACTOR') &&
                        <div className="action add-attribute-action"
                             onClick={ () => setShowAddAttributeModal(true) }>Add
                            Attribute</div>
                    }
                    { (artefact.type === 'CLASS' || artefact.type === 'ACTOR') &&
                        <div className="action add-relationship-action"
                             onClick={ () => setShowAddRelationshipModal(true) }>
                            Add Relationship
                        </div>
                    }
                    { (artefact.type === 'US') &&
                        <div className="action add-class-action" onClick={ () => setShowAddClassModal(true) }>Add
                            Class</div>
                    }
                </div>
            </div>
        </>
    )
};