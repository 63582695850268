import {
    GET_USER_ERROR,
    GET_USER_PENDING,
    GET_USER_SUCCESS,
    LOGIN_SUBMIT_ERROR,
    LOGIN_SUBMIT_PENDING,
    LOGIN_SUBMIT_SUCCESS,
    LOGOUT,
    REGISTRATION_SUBMIT_ERROR,
    REGISTRATION_SUBMIT_PENDING,
    REGISTRATION_SUBMIT_SUCCESS,
    UPDATE_USER_ERROR,
    UPDATE_USER_PENDING,
    UPDATE_USER_SUCCESS
} from "./user.types";
import {JWT_LOCAL_STORAGE, USER_LOCAL_STORAGE} from "../../constants";

const INITIAL_STATE = {
    user: {
        firstname: "",
        lastname: "",
        email: ""
    },
    pending: false,
    error: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REGISTRATION_SUBMIT_PENDING:
            return {
                ...state,
                error: null,
                pending: true
            };
        case REGISTRATION_SUBMIT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            };
        case REGISTRATION_SUBMIT_SUCCESS:
            return {
                ...state,
                pending: false,
            };
        case LOGIN_SUBMIT_PENDING:
            return {
                ...state,
                error: null,
                pending: true
            };
        case LOGIN_SUBMIT_SUCCESS:
            return {
                ...state,
                user: action.payload,
                pending: false
            };
        case LOGIN_SUBMIT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        case GET_USER_PENDING:
            return {
                ...state,
                error: null,
                pending: true
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
                pending: false
            };
        case GET_USER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        case UPDATE_USER_PENDING:
            return {
                ...state,
                pending: false
            }
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                user: action.payload.user
            }
        case UPDATE_USER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.err
            }
        case LOGOUT:
            localStorage.removeItem(JWT_LOCAL_STORAGE)
            localStorage.removeItem(USER_LOCAL_STORAGE)
            return {
                ...state,
                user: {},
                pending: false,
            }
        default:
            return state;
    }
};

export default reducer;