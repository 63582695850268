import React from "react";
import DateFormatter from "./dateFormatter";

function DeleteDuplicatesAndSortArray(array) {
    let arrayTimeSec = [];
    let allDates = [];

    array.map((item) => {
        let dateInSeconds = Date.parse(item)
        arrayTimeSec.push(dateInSeconds)
    })
    arrayTimeSec.sort()

    arrayTimeSec.map((item) => {
        let dateInString = new Date(item);
        let date = DateFormatter(dateInString)
        allDates.push(date)
    })

    return [...new Set(allDates)];
}

export default DeleteDuplicatesAndSortArray