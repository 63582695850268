import React, { useState } from 'react'
import './TracingView.css'
import NavBar from '../navigation/NavBar'
import UserStoryMenu from './userStoryMenu/UserStoryMenu'
import GraphView from './visualizations/graphView/GraphView'
import Filter from './filter/Filter'
import { useParams } from 'react-router-dom'
import { authHeader } from '../../helpers/auth-header'
import axios from 'axios'
import constants from '../../constants'
import { Spinner } from 'react-bootstrap'
import { EntityType, ViewType } from './Types'
import DetailsBanner from './detailsBanner/DetailsBanner'
import { useQuery } from '@tanstack/react-query'
import ViewTabs from './viewTabs/ViewTabs'
import ClassDiagramView from './visualizations/classDiagramView/ClassDiagramView'
import MatrixView from './visualizations/matrixView/MatrixView'
import ListView from './visualizations/listView/ListView'


export default function TracingView() {
    const { projectId } = useParams()
    const { loading, error, data } = useQuery({
        queryKey: [`tracing-data-${ projectId }`],
        queryFn: async () => {
            const requestConfig = {
                headers: authHeader(),
            }
            const response = await axios.get(`${ constants().apiUrl }/project/${ projectId }/conceptual-model`, requestConfig)
            return response.data.data
        },
        // staleTime: Infinity,
    })
    const [selectedUserStories, setSelectedUserStories] = useState([])
    const [currentView, setCurrentView] = useState(ViewType.GRAPH)
    const [visibleEntities, setVisibleEntities] = useState({
        [EntityType.CLASS]: true,
        [EntityType.ACTOR]: true,
        [EntityType.ATTRIBUTE]: true,
        [EntityType.RELATIONSHIP]: true,
        [EntityType.US]: true,
    })
    const [entityColors, setEntityColors] = useState({
        [EntityType.CLASS]: '#638ef3',
        [EntityType.ACTOR]: '#56cdb0',
        [EntityType.ATTRIBUTE]: '#cada4e',
        [EntityType.RELATIONSHIP]: '#d69b57',
        [EntityType.US]: '#e44f4f',
    })
    const [selectedArtefact, setSelectedArtefact] = useState(null)

    return (
        <>
            <NavBar
                account={ true }
                projects={ true }
                projectsSettings={ false }
            />
            {
                (loading || !data) ?
                    <div className="loading-container">
                        <Spinner animation="grow"/>
                    </div> :
                    <div className="container">
                        <div className="user-story-menu">
                            <UserStoryMenu
                                userStories={ data.userStories }
                                setSelectedUserStories={ setSelectedUserStories }
                                selectedUserStories={ selectedUserStories }
                                setSelectedArtefact={ setSelectedArtefact }
                            />
                        </div>
                        <div className="graph">
                            <ViewTabs
                                currentView={ currentView }
                                setCurrentView={ setCurrentView }
                            />
                            { currentView === ViewType.GRAPH &&
                                <GraphView
                                    entityColors={ entityColors }
                                    selectedUserStories={ selectedUserStories }
                                    visibleEntities={ visibleEntities }
                                    entities={ data.entities }
                                    userStories={ data.userStories }
                                    setSelectedArtefact={ setSelectedArtefact }
                                />
                            }
                            { currentView === ViewType.CLASS_DIAGRAM &&
                                <ClassDiagramView
                                    setSelectedArtefact={ setSelectedArtefact }
                                    selectedUserStories={ selectedUserStories }
                                    entityColors={ entityColors }
                                    entities={ data.entities }
                                />
                            }
                            { currentView === ViewType.MATRIX &&
                                <MatrixView
                                    selectedUserStories={ selectedUserStories }
                                    visibleEntityTypes={ visibleEntities }
                                    entityColors={ entityColors }
                                    entities={ data.entities }
                                    userStories={ data.userStories }
                                />
                            }
                            { currentView === ViewType.LIST &&
                                <ListView
                                    selectedUserStories={ selectedUserStories }
                                    visibleEntityTypes={ visibleEntities }
                                    entityColors={ entityColors }
                                    entities={ data.entities }
                                    userStories={ data.userStories }
                                />
                            }
                            { selectedArtefact &&
                                <DetailsBanner
                                    setSelectedArtefact={ setSelectedArtefact }
                                    entityColors={ entityColors }
                                    selectedArtefact={ selectedArtefact }
                                    userStories={ data.userStories }
                                    entities={ data.entities }
                                />
                            }
                        </div>
                        <div className="filter-menu">
                            <Filter
                                entityColors={ entityColors }
                                setEntityColors={ setEntityColors }
                                visibleEntities={ visibleEntities }
                                setVisibleEntities={ setVisibleEntities }
                            />
                        </div>
                    </div>
            }
        </>
    )
}
