import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { JWT_LOCAL_STORAGE } from '../constants'

export const PrivateRoute = () => {
    const location = useLocation()
    return localStorage.getItem(JWT_LOCAL_STORAGE)
        ? <Outlet/>
        : <Navigate
            to={ '/' }
            replace
            state={ { from: location } }
        />
}

export const PublicRoute = () => {
    const location = useLocation()
    return localStorage.getItem(JWT_LOCAL_STORAGE)
        ? <Navigate
            to={ '/project' }
            replace
            state={ { from: location } }
        />
        : <Outlet/>
}
