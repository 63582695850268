import axios from "axios";
import store from "store";
import {
    ADD_COLLABORATORS_ERROR,
    ADD_COLLABORATORS_PENDING,
    ADD_COLLABORATORS_SUCCESS,
    CLEAN_CURRENT_COLLABORATORS_PROJECT,
    DEL_COLLABORATORS_ERROR,
    DEL_COLLABORATORS_PENDING,
    DEL_COLLABORATORS_SUCCESS,
    GET_COLLABORATORS_ERROR,
    GET_COLLABORATORS_PENDING,
    GET_COLLABORATORS_SUCCESS,
    UPDATE_COLLABORATORS_ERROR,
    UPDATE_COLLABORATORS_PENDING,
    UPDATE_COLLABORATORS_SUCCESS
} from 'model/collaborators/collaborators.types'
import constants from '../../constants/index'
import {authHeader} from "helpers/auth-header";

export const fetchCollaborators = (projectId) => {
    const requestConfig = {
        headers: authHeader()
    };
    return (dispatch) => {
        dispatch(fetchCollaboratorsRequest());
        let collaboratorsPromise = axios.get(constants().apiUrl + '/project/' + projectId + '/collaborator', requestConfig)
        collaboratorsPromise.then(
            res => {
                dispatch(getAllCollaboratorsSuccess(res.data.collaborators))
            }
        ).catch(function (err) {
            dispatch(getAllCollaboratorsError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const fetchCollaboratorsRequest = () => ({
    type: GET_COLLABORATORS_PENDING,
});

export const getAllCollaboratorsSuccess = collaborators => ({
    type: GET_COLLABORATORS_SUCCESS,
    payload: {collaborators}
});

export const getAllCollaboratorsError = err => ({
    type: GET_COLLABORATORS_ERROR,
    payload: err
});

export const addCollaborator = (projectId, userId) => {
    const requestConfig = {
        headers: authHeader()
    };

    return (dispatch) => {
        dispatch(addCollaboratorRequest());
        let collaboratorsPromise = axios.put(constants().apiUrl + '/project/' + projectId + '/collaborator/' + userId, null, requestConfig)
        collaboratorsPromise.then(
            res => {
                dispatch(addCollaboratorSuccess(res.data.collaborator))
            }
        ).catch(function (err) {
            dispatch(addCollaboratorError(err.response.data))
            console.log('ERROR: ', err)
        })
    }
};

export const addCollaboratorRequest = () => ({
    type: ADD_COLLABORATORS_PENDING
});

export const addCollaboratorSuccess = collaborator => ({
    type: ADD_COLLABORATORS_SUCCESS,
    payload: collaborator
});

export const addCollaboratorError = err => ({
    type: ADD_COLLABORATORS_ERROR,
    payload: err
});

export const delCollaborator = (projectId, collaboratorId) => {
    console.log("delete " + collaboratorId)
    const requestConfig = {
        headers: authHeader()
    };

    return (dispatch) => {
        dispatch(delCollaboratorRequest());
        let collaboratorsPromise = axios.delete(constants().apiUrl + '/project/' + projectId + '/collaborator/' + collaboratorId, requestConfig)
        collaboratorsPromise.then(
            res => {
                dispatch(delCollaboratorSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(delCollaboratorError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const delCollaboratorRequest = () => ({
    type: DEL_COLLABORATORS_PENDING
});

export const delCollaboratorSuccess = data => ({
    type: DEL_COLLABORATORS_SUCCESS,
    payload: data
});

export const delCollaboratorError = err => ({
    type: DEL_COLLABORATORS_ERROR,
    payload: err
});

export const updateCollaborator = (projectId, collaboratorId, values) => {
    const requestConfig = {
        headers: authHeader()
    };

    let valuesNew = {...values};
    if (valuesNew.collaboratorId) {
        delete valuesNew.collaboratorId
    }

    return (dispatch) => {
        dispatch(updateCollaboratorRequest());
        let collaboratorsPromise = axios.put(constants().apiUrl + '/project/' + projectId + '/collaborator/' + collaboratorId, valuesNew, requestConfig)
        collaboratorsPromise.then(
            res => {
                dispatch(updateCollaboratorSuccess(res.data))
            }
        ).catch(function (err) {
            dispatch(updateCollaboratorError(err))
            console.log('ERROR: ', err)
        })
    }
};

export const updateCollaboratorRequest = () => ({
    type: UPDATE_COLLABORATORS_PENDING
});

export const updateCollaboratorSuccess = data => ({
    type: UPDATE_COLLABORATORS_SUCCESS,
    payload: data
});

export const updateCollaboratorError = err => ({
    type: UPDATE_COLLABORATORS_ERROR,
    payload: err
});

export const cleanCollaborators = () => ({
    type: CLEAN_CURRENT_COLLABORATORS_PROJECT
})

const getNextBiggestCollaboratorNumberFromCurrent = () => {
    let collaborator = store.getCollaborator();
    let biggestNumber = 0;
    collaborator.projects.current.collaborators.collaboratorsList.map(collaborator => {
        if (collaborator.collaboratorNumber > biggestNumber) {
            biggestNumber = collaborator.collaboratorNumber
        }
    })
    return biggestNumber + 1
}