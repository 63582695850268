import React from 'react'
import { Card } from 'react-bootstrap'
import './AddProjectCard.css'
import { Plus } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'


function AddProjectCard() {
    const navigate = useNavigate()

    return (
        <Card as="a" onClick={ () => navigate('/project/new') } id={ 'cardStyleCreate' }>
            <Card.Body id={ 'cardBody' }>
                <Card.Title id={ 'cardTitle' }>Create New Project</Card.Title>
                <Plus size={ 110 }/>
            </Card.Body>
        </Card>
    )
}

export default AddProjectCard
