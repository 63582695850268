import React from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import './Filtering.css'
import "../form/buttons/button.css"
import ListOfCheckboxes from "./ListOfCheckboxes";
import store from "../../store";
import {reduxForm} from "redux-form";
import Button from "components/form/buttons/SubmitButton";
import SortArray from "../../helpers/sortArray";
import DeleteDuplicatesAndSortArray from "../../helpers/deleteDuplicatesAndSortArray";
import {cleanFilterCriteria, sendFilteringForm} from "model/filtering/filterCriteria.action";
import {connect} from "react-redux";

const mapStateToProps = state => {
    return {
        initialValues: state.projects.current.filterCriteria
    }
}

let Filtering = ({handleSubmit, toggleFilter, reset, ...props}) => {
    const currentProject = store.getState().projects.current

    const getParams = (values, type) => {
        let list = [];
        values.map(item => {
            list.push(item[type])
        })
        return list
    }

    let sprints = currentProject.sprints.sprintsList.map((item) => {
            return {
                id: item.sprintId,
                label: item.name
            }
        }
    );

    let states = currentProject.states.statesList.map((item) => {
            return {
                id: item.stateId,
                label: item.name
            }
        }
    );

    let themes = currentProject.themes.themesList.map((item) => {
            return {
                id: item.themeId,
                label: item.name
            }
        }
    );

    let userRoles = currentProject.userRoles.userRolesList.map((item) => {
            return {
                id: item.userRoleId,
                label: item.name
            }
        }
    );

    let collaborators = currentProject.collaborators.collaboratorsList.map((item) => {
            return {
                id: item.userId,
                label: item.firstname
            }
        }
    );

    let sortedPriority = SortArray(getParams(currentProject.userStories.userStoryList, "priority"))
    let priorities = sortedPriority.map((item, index) => {
            return {
                id: index,
                label: item
            }
        }
    );

    let sortedEstimate = SortArray(getParams(currentProject.userStories.userStoryList, "estimate"))
    let estimations = sortedEstimate.map((item, index) => {
            return {
                id: index,
                label: item
            }
        }
    );

    let sortedDeleteCreationDate = DeleteDuplicatesAndSortArray(getParams(currentProject.userStories.userStoryList, "creationDate"))
    let creationDates = sortedDeleteCreationDate.map((item, index) => {
            return {
                id: index,
                label: item
            }
        }
    );

    return (
        <div className={'filteringDiv'}>
            <h1 className={'sidebarTitle'}>Filtering</h1>
            <form onSubmit={handleSubmit}>
                <Navbar>
                    <Nav className={'flex-column  navOfLists'}>
                        <ListOfCheckboxes options={sprints} title={"Sprints"} name={"sprints"}/>
                        <ListOfCheckboxes options={states} title={"States"} name={"states"}/>
                        <ListOfCheckboxes options={themes} title={"Themes"} name={"themes"}/>
                        <ListOfCheckboxes options={userRoles} title={"User roles"} name={"userRoles"}/>
                        <ListOfCheckboxes options={collaborators} title={"Collaborators"} name={"collaborators"}/>
                        <ListOfCheckboxes options={priorities} title={"Priorities"} name={"priorities"}/>
                        <ListOfCheckboxes options={estimations} title={"Estimations"} name={"estimations"}/>
                        <ListOfCheckboxes options={creationDates} title={"Creation dates"} name={"creationDates"}/>
                    </Nav>
                </Navbar>
                <Button
                    type="submit"
                    styleClass="btn-primary-custom-btn-filtering"
                    name={'Filter'}
                />
                <Button
                    type="button"
                    styleClass="btn-primary-custom-btn-filtering"
                    name={'Clear'}
                    onClick={() => {
                        store.dispatch(reset('filtering'))
                        store.dispatch(cleanFilterCriteria())
                    }}
                />
            </form>
        </div>
    );
}

const onSubmit = (values, dispatch, props) => {
    store.dispatch(sendFilteringForm(values));
    props.toggleFilter()
}

Filtering = reduxForm({
    form: 'filtering',
    onSubmit,
    enableReinitialize: true
})(Filtering)

export default connect(mapStateToProps, null)(Filtering)