import './Modal.css'

export default function SubArtefactPicker({ subArtefacts, pickedSubArtefacts, setPickedSubArtefacts, isReadOnly }) {

    function isPicked(subArtefact) {
        return pickedSubArtefacts
            .map(psa => psa.id)
            .includes(subArtefact.id)
    }

    function toggleArtefact(subArtefact) {
        if (isPicked(subArtefact)) {
            setPickedSubArtefacts(old => old.filter(psa => psa.id !== subArtefact.id))
        } else {
            setPickedSubArtefacts([...pickedSubArtefacts, subArtefact])
        }
    }

    return (
        <>
            { !isReadOnly &&
                <div>Select the words from which the new entity is derived:</div>
            }
            <div className="sub-artefact-select">
                {
                    subArtefacts.map((a, index) => (
                        <div
                            key={ index }
                            className={ `sub-artefact-item ${ isPicked(a) ? 'selected' : '' } ${ isReadOnly ? '' : 'write' }` }
                            onClick={ () => toggleArtefact(a) }
                        >
                            { a.name }
                        </div>
                    ))
                }
            </div>
        </>
    )
};