import './UserStoryList.css'

export default function UserStoryList({ userStories, selectedUserStory, setSelectedUserStory }) {
    const getName = (us) => {
        return `US${ us.uniqueUserStoryProjectId }`
    }
    return (
        <>
            { userStories.map(us =>
                <div
                    className={ `details-user-story-item ${ us.id === selectedUserStory.id ? ' selected' : '' }` }
                    key={ us.id }
                    onClick={ () => setSelectedUserStory(us) }
                >
                    <span className="us-name">{ getName(us) }</span>
                    <span>{ us.userStory }</span>
                </div>,
            ) }
        </>
    )
};