import React from "react";
import {Field, Form, reduxForm, reset} from "redux-form";
import {Trash} from "react-bootstrap-icons"
import store from "store";

import FieldInput from "components/form/fieldInput/FieldInput";
import {maxLength100, minLength3, required} from "validation/InputValidation";
import Button from "components/form/buttons/SubmitButton";
import {PROJECT_SETTINGS_USERROLE_CREATE, PROJECT_SETTINGS_USERROLE_EDIT} from "constants/index"
import {addUserRole, delUserRole, updateUserRole} from "model/userRoles/userRoles.actions";

function UserRoleForm({handleSubmit, mode, ...props}) {
    let projectId = props.projectId

    return (
        <>
            <Form id="userRoleSettings" onSubmit={handleSubmit}>
                <Field name="name"
                       label="Name"
                       type="text"
                       component={FieldInput}
                       placeholder="Name"
                       aria-label="Recipient's username"
                       aria-describedby="basic-addon2"
                       validate={[required, minLength3, maxLength100]}
                />
                <Button
                    type="submit"
                    styleClass={mode === PROJECT_SETTINGS_USERROLE_CREATE ? "btn-primary-custom-btn-primary" : "btn-primary-custom-btn-submit"}
                    name={mode === PROJECT_SETTINGS_USERROLE_CREATE ? "Create user role" : "Edit user role"}
                />
            </Form>
            {mode === PROJECT_SETTINGS_USERROLE_EDIT &&
            <Button styleClass="btn-primary-custom-btn-naked deleteUserRoleButton" name={<Trash/>}
                    onClick={() => onDelete(projectId, props.initialValues.userRoleId)}/>
            }
        </>
    )
}

const onSubmit = (values, dispatch, props) => {
    let projectId = props.projectId
    let userRoleId = props.initialValues ? props.initialValues.userRoleId : null
    if (!values.userRoleId) {
        store.dispatch(addUserRole(projectId, values))
        store.dispatch(reset('newUserRole'));
    } else {
        store.dispatch(updateUserRole(projectId, userRoleId, values))
    }
}

const onDelete = (projectId, stateId) => {
    store.dispatch(delUserRole(projectId, stateId))
}

UserRoleForm = reduxForm({
    enableReinitialize: true,
    onSubmit
})(UserRoleForm)

export default UserRoleForm