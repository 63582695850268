import Modal from 'react-bootstrap/Modal'
import React from 'react'
import { ColorPicker, useColor } from 'react-color-palette'

export default function SelectColorModal({ currentColor, show, onHide }) {
    const [color, setColor] = useColor(currentColor)

    return (
        <Modal
            show={ show }
            onHide={ () => onHide(null) }
            size={ 'sm' }
            aria-labelledby={ 'contained-modal-title-vcenter' }
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id={ 'contained-modal-title-vcenter' }>
                    Select Color
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={ 'color-modal' }>
                <ColorPicker color={ color } onChange={ setColor }/>
                <button onClick={ () => onHide(color) }>Save</button>
            </Modal.Body>
        </Modal>
    )
}