import {
    ADD_PROJECT_ERROR,
    ADD_PROJECT_PENDING,
    ADD_PROJECT_SUCCESS,
    CLEAN_CURRENT_PROJECT,
    DEL_PROJECT_ERROR,
    DEL_PROJECT_PENDING,
    DEL_PROJECT_SUCCESS,
    GET_PROJECT_BY_ID_ERROR,
    GET_PROJECT_BY_ID_PENDING,
    GET_PROJECT_BY_ID_SUCCESS,
    GET_USERPROJECTS_ERROR,
    GET_USERPROJECTS_PENDING,
    GET_USERPROJECTS_SUCCESS
} from './project.types';

const INITIAL_STATE = {
    ownProjects: [],
    colProjects: [],
    pending: false,
    error: null
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PROJECT_BY_ID_PENDING:
            return {
                ...state,
                pending: true,
            }
        case GET_PROJECT_BY_ID_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }
        case GET_PROJECT_BY_ID_SUCCESS:
            return {
                ...state,
                pending: false,
                current: {
                    ...action.payload.project
                }
            }
        case CLEAN_CURRENT_PROJECT:
            return {
                ...state,
                current: {},
                error: null
            }
        case ADD_PROJECT_PENDING:
            return {
                ...state,
                pending: true
            }
        case ADD_PROJECT_SUCCESS:
            return {
                ...state,
                pending: false
            }
        case DEL_PROJECT_PENDING:
            return {
                ...state,
                pending: false
            }
        case DEL_PROJECT_SUCCESS:
            return {
                ...state,
                pending: false,
                ownProjects: state.ownProjects.filter(project => {
                        console.log(project.projectId !== action.payload.projectId)
                        return project.projectId !== action.payload.projectId
                    }
                )
            }
        case DEL_PROJECT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.err
            }
        case ADD_PROJECT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload
            }

        case GET_USERPROJECTS_PENDING:
            return {
                ...state,
                pending: true
            };
        case GET_USERPROJECTS_SUCCESS:
            return {
                ...state,
                pending: false,
                ...action.payload.projects
            };
        case GET_USERPROJECTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload.err
            };
        default:
            return state;
    }
};

export default reducer;