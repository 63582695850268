import './Modal.css'
import Modal from 'react-bootstrap/Modal'
import { Spinner } from 'react-bootstrap'
import React, { useState } from 'react'
import SubArtefactPicker from './SubArtefactPicker'
import ClassDropdown from './ClassDropdown'
import { authHeader } from '../../../helpers/auth-header'
import axios from 'axios'
import constants from '../../../constants'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

export default function AddAttributeModal({ currentClass, classes, userStory, show, onHide }) {
    const queryClient = useQueryClient()
    const [loading, setLoading] = useState(false)
    const [pickedSubArtefacts, setPickedSubArtefacts] = useState([])
    const [attributeName, setAttributeName] = useState('')
    const [selectedClass, setSelectedClass] = useState(currentClass)
    const { projectId } = useParams()

    async function onSave() {
        const requestConfig = {
            headers: authHeader(),
        }
        const body = {
            name: attributeName,
            mainArtefactId: userStory.id,
            mainEntityId: selectedClass.id,
            position: pickedSubArtefacts.map(psa => psa.index),
            type: 'ATTRIBUTE',
        }
        setLoading(true)
        await axios.post(`${ constants().apiUrl }/project/${ projectId }/conceptual-model/sub-entity`, body, requestConfig)
        await queryClient.invalidateQueries({ queryKey: [`tracing-data-${ projectId }`] })
        setLoading(false)
        onHide()
    }

    return (
        <Modal
            show={ show }
            onHide={ onHide }
            size={ 'lg' }
            aria-labelledby={ 'contained-modal-title-vcenter' }
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id={ 'contained-modal-title-vcenter' }>
                    Add Attribute
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                { loading ?
                    <div className="loading-container">
                        <Spinner animation="grow"/>
                    </div> :
                    <>
                        <div className="modal-input-group">
                            <label htmlFor="attribute-name">Attribute name:</label>
                            <input
                                type="text"
                                name="attribute-name"
                                value={ attributeName }
                                onChange={ (e) => setAttributeName(e.target.value) }
                            />
                        </div>
                        <ClassDropdown
                            classes={ classes }
                            selectedClass={ selectedClass }
                            setSelectedClass={ setSelectedClass }
                        />
                        <SubArtefactPicker
                            subArtefacts={ userStory.subArtefacts }
                            pickedSubArtefacts={ pickedSubArtefacts }
                            setPickedSubArtefacts={ setPickedSubArtefacts }
                        />
                        <div className="modal-button-row">
                            <button onClick={ onHide }>Cancel</button>
                            <button onClick={ onSave }>Save</button>
                        </div>
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}