export const GET_USERSTORIES_SUCCESS = 'GET_USERSTORIES_SUCCESS';
export const GET_USERSTORIES_PENDING = 'GET_USERSTORIES_PENDING';
export const GET_USERSTORIES_ERROR = 'GET_USERSTORIES_ERROR';

export const DEL_USERSTORY_SUCCESS = 'DEL_USERSTORY_SUCCESS';
export const DEL_USERSTORY_PENDING = 'DEL_USERSTORY_PENDING';
export const DEL_USERSTORY_ERROR = 'DEL_USERSTORY_ERROR';

export const SET_CURRENT_USERSTORY = 'SET_CURRENT_USERSTORY';
export const ADD_ONE_USERSTORY = 'ADD_ONE_USERSTORY';
export const UPDATE_ONE_USERSTORY = 'UPDATE_ONE_USERSTORY';

export const UPDATE_USERSTORY_SUCCESS = 'UPDATE_USERSTORY_SUCCESS';
export const UPDATE_USERSTORY_PENDING = 'UPDATE_USERSTORY_PENDING';
export const UPDATE_USERSTORY_ERROR = 'UPDATE_USERSTORY_ERROR';