import React from "react";
import './sprint.css'
import StateView from "components/stateContainer/state/stateView";

let Sprint = ({sprintName, states, userStories, sprintId, ...props}) => {
    return (
        <div className="sprint">
            <div className="title-sprint">{sprintName}</div>
            <StateView
                projectId={props.projectId}
                states={states}
                sprintId={sprintId}
                sprintName={sprintName}
                openModalEdit={props.openModalEdit}
            />
        </div>
    )
}

export default Sprint;