import React from "react";
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"
import './FieldDatePicker.css'

import FieldInput from "components/form/fieldInput/FieldInput";

const FieldDatePicker = ({input, placeholder, minDate, maxDate, ...props}) => {

    const inputValueToDate = (value) => {
        let newDate
        if (value !== "") {
            let dateArray = value.split(".")
            newDate = new Date(Number(dateArray[2]), Number(dateArray[1] - 1), Number(dateArray[0]))
        }
        return newDate;
    }

    return (
        <DatePicker
            dateFormat="dd.MM.yyyy"
            selected={typeof input.value === "string" ? inputValueToDate(input.value) : input.value}
            onChange={input.onChange}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
            minDate={minDate ? minDate : Date.now()}
            maxDate={maxDate}
            disabledKeyboardNavigation
            placeholderText={placeholder}
            customInput={
                <FieldInput
                    {...input}
                    {...props}
                />
            }
        />
    )
};

export default FieldDatePicker