import './state.css'
import {InitDropZone} from "components/interaction/Dropzone";
import React from 'react';
import {getUserStoriesBySprintState} from "model/userStories/userStories.selector";
import {connect} from "react-redux";
import USCard from "components/USCard";
import store from "store";
import {delUserStoryRequest, setCurrentUserStory} from "model/userStories/userStories.actions";

class State extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "dropzone" + props.stateId + props.sprintId
        }
    }

    componentDidMount() {
        InitDropZone(this.state.id, this.props.stateId, this.props.sprintId, this.props.projectId);
    };

    editUserStoryModal(userStory) {
        this.props.openModalEdit()
        store.dispatch(setCurrentUserStory(userStory))
    }

    deleteUserStory(userStory) {
        store.dispatch(delUserStoryRequest(userStory, this.props.projectId))
    }

    render() {
        return (
            <div className="state">
                <div className="state-title">
                    {this.props.stateName}
                </div>
                <div id={this.state.id} className='dropzone'>
                    {
                        this.props.userStories.map((item) => {
                            return <USCard class={"card"}
                                           key={item.userStoryId}
                                           id={item.userStoryId}
                                           userStory={item}
                                           style={{order: item.userStoryId}}
                                           editLink={this.editUserStoryModal.bind(this)}
                                           deleteLink={this.deleteUserStory.bind(this)}
                            />
                        })
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        userStories: getUserStoriesBySprintState(state.projects.current, props.sprintId, props.stateId)
    }
}

export default connect(mapStateToProps)(State);