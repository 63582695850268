import Modal from 'react-bootstrap/Modal'
import { Spinner } from 'react-bootstrap'
import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { authHeader } from '../../../helpers/auth-header'
import axios from 'axios'
import constants from '../../../constants'
import SubArtefactPicker from './SubArtefactPicker'

export default function DeleteInstanceModal({ artefact, currentUserStory, show, onHide }) {
    const queryClient = useQueryClient()
    const [loading, setLoading] = useState(false)
    const { projectId } = useParams()

    async function onDelete() {
        const requestConfig = {
            headers: authHeader(),
        }
        setLoading(true)
        await axios.delete(`${ constants().apiUrl }/project/${ projectId }/conceptual-model/instance/${ getInstance().id }`, requestConfig)
        await queryClient.invalidateQueries({ queryKey: [`tracing-data-${ projectId }`] })
        setLoading(false)
        onHide()
    }

    function getInstance() {
        return artefact.instances.find(instance => instance.mainArtefactId === currentUserStory.id)
    }

    function getPosition() {
        const instance = getInstance()
        return currentUserStory.subArtefacts.filter(sa => instance.position.includes(sa.index))
    }

    return (
        <Modal
            show={ show }
            onHide={ onHide }
            size={ 'm' }
            aria-labelledby={ 'contained-modal-title-vcenter' }
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id={ 'contained-modal-title-vcenter' }>
                    Delete Instance
                </Modal.Title>
            </Modal.Header>

            { artefact &&
                <Modal.Body className={ 'color-modal' }>
                    { loading ?
                        <div className="loading-container">
                            <Spinner animation="grow"/>
                        </div> :
                        <>
                            <div>Are you sure you want to delete the following instance?</div>
                            <div>Type: { artefact.type.toLowerCase() }</div>
                            <div>Name: { artefact.name }</div>
                            <div>ID: { artefact.id }</div>
                            <div>User Story: {`US-${currentUserStory.uniqueUserStoryProjectId}`}</div>
                            <button onClick={ onDelete }>Yes</button>
                            <button onClick={ onHide }>Cancel</button>
                            <SubArtefactPicker
                                subArtefacts={ currentUserStory.subArtefacts }
                                pickedSubArtefacts={ getPosition() }
                                setPickedSubArtefacts={ () => null }
                                isReadOnly={ true }
                            />
                        </>
                    }
                </Modal.Body>
            }
        </Modal>
    )
}