import React from "react";
import './Confirmation.css'
import {FormControl, InputGroup} from "react-bootstrap";

function USConfirmation() {
    return (
        <div className="us_confirmation">
            <label htmlFor="basic-url">Acceptance test</label>
            <InputGroup className="mb-3">
                <FormControl
                    placeholder="Acceptance test" as="textarea" aria-label="With textarea"/>
            </InputGroup>
        </div>

    )
}

export default USConfirmation