import './MatrixView.css'
import { useEffect, useState } from 'react'

export default function MatrixView({ userStories, entities, entityColors, selectedUserStories, visibleEntityTypes }) {
    const [visibleEntities, setVisibleEntities] = useState([])
    const [visibleUserStories, setVisibleUserStories] = useState([])

    useEffect(() => {
        const filteredEntities = []
        for (const key in visibleEntityTypes) {
            if (visibleEntityTypes[key]) {
                filteredEntities.push(...entities.filter(e => e.type === key))
            }
        }
        setVisibleEntities(filteredEntities)
    }, [visibleEntityTypes])

    useEffect(() => {
        if (selectedUserStories.length)
            setVisibleUserStories(userStories.filter(us => selectedUserStories.includes(us.id)))
        else
            setVisibleUserStories(userStories)
    }, [selectedUserStories])

    function hasLink(userStory, entity) {
        for (const instance of entity.instances) {
            if (instance.mainArtefactId === userStory.id)
                return true
        }
        return false
    }

    return (
        <div className="matrix-view-container">
            <table className="matrix-view" border="1">
                <thead>
                <tr>
                    <th></th>
                    {
                        visibleUserStories.map(us => (
                            <th
                                className="header-item"
                                style={ { background: entityColors['US'] } }
                            >
                                <div>
                                    US { us.uniqueUserStoryProjectId }
                                </div>
                            </th>
                        ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    visibleEntities.map(entity => (
                        <tr>
                            <td
                                className="entity-item"
                                style={ { background: entityColors[entity.type] } }
                            >
                                <div>
                                    { entity.name }
                                </div>
                            </td>
                            {
                                visibleUserStories.map(us => (
                                    <td className="link-item">
                                        <div>
                                            { hasLink(us, entity) ? 'X' : '' }
                                        </div>
                                    </td>
                                ))
                            }
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>

    )
}