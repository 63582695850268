import React, {useState} from 'react';
import {FormControl} from 'react-bootstrap';

import Select from "react-select";

const TypeHead = ({items, meta: {touched, error}, input, ...props}) => {
    const [inputValue, setInputValue] = useState(0);

    const handleBlur = () => {
        setTimeout(() => {
            input.onBlur(input.value);
        }, 1);
    };

    const handleOnChange = (inputValue, params) => {
        if (params.action === 'select-option') {
            setInputValue(inputValue.value);
            input.onChange(inputValue.value)
        }
    }

    return (
        <>
            <Select
                id="selectCollaborator"
                valueKey="value"
                options={items}
                onBlur={handleBlur}
                onChange={handleOnChange}
                searchable
                {...props}
            />
            {touched && error && <FormControl.Feedback type="invalid" tooltip>{error}</FormControl.Feedback>}
        </>
    )
};

export default TypeHead
