import {
    CLEAN_CURRENT_FILTERCRITERIA_PROJECTS,
    FILTERINGFORM_SUBMIT_ERROR,
    FILTERINGFORM_SUBMIT_PENDING,
    FILTERINGFORM_SUBMIT_SUCCESS
} from "./filterCriteria.types";


const INITIAL_STATE = {
    sprints: [],
    states: [],
    themes: [],
    userRoles: [],
    collaborators: [],
    priorities: [],
    estimations: [],
    creationDates: [],
    set: false,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAN_CURRENT_FILTERCRITERIA_PROJECTS:
            return {
                ...state,
                sprints: [],
                states: [],
                themes: [],
                userRoles: [],
                collaborators: [],
                priorities: [],
                estimations: [],
                creationDates: [],
                set: false
            }
        case FILTERINGFORM_SUBMIT_PENDING:
            return {
                ...state,
                sprints: action.payload.sprints,
                states: action.payload.states,
                themes: action.payload.themes,
                userRoles: action.payload.userRoles,
                collaborators: action.payload.collaborators,
                priorities: action.payload.priorities,
                estimations: action.payload.estimations,
                creationDates: action.payload.creationDates,
                set: true
            };
        case FILTERINGFORM_SUBMIT_ERROR:
            return {
                ...state,
                set: false,
            };
        case FILTERINGFORM_SUBMIT_SUCCESS:
            return {
                ...state,
                set: true,
            };
        default:
            return state;
    }
};

export default reducer;