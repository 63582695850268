import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.css'
import 'bootstrap/dist/css/bootstrap.css'
import App from 'App'
import * as serviceWorker from 'serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Imports for redux
import { Provider } from 'react-redux'
import store from 'store'

const queryClient = new QueryClient()

ReactDOM.render(
    <Provider store={ store }>
        <React.StrictMode>
            <BrowserRouter>
                <QueryClientProvider client={ queryClient }>
                    <App/>
                </QueryClientProvider>
            </BrowserRouter>
        </React.StrictMode>
    </Provider>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
