import './ListView.css'
import { useEffect, useState } from 'react'

export default function ListView({ userStories, entities, entityColors, selectedUserStories, visibleEntityTypes }) {
    const [visibleEntities, setVisibleEntities] = useState([])

    useEffect(() => {
        const filteredEntities = []
        for (const key in visibleEntityTypes) {
            if (visibleEntityTypes[key]) {
                filteredEntities.push(...entities.filter(e => e.type === key))
            }
        }
        setVisibleEntities(filteredEntities)
    }, [visibleEntityTypes, selectedUserStories])

    function getSourceArtefact(instance) {
        return userStories.find(us => us.id === instance.mainArtefactId)
    }

    return (
        <div className="list-view-container">
            <table className="list-view" border="1">
                <thead>
                <tr>
                    <th>Source Artefact</th>
                    <th>Target Artefact</th>
                </tr>
                </thead>
                <tbody>
                {
                    visibleEntities.map(entity => (
                        entity.instances
                            .filter(instance => selectedUserStories.length === 0 || selectedUserStories.includes(instance.mainArtefactId))
                            .map(instance => (
                                <tr>
                                    <td
                                        className="source-artefact"
                                        style={ { background: entityColors['US'] } }
                                    >
                                        <div>
                                            US { getSourceArtefact(instance).uniqueUserStoryProjectId }
                                        </div>
                                    </td>
                                    <td
                                        className="target-artefact"
                                        style={ { background: entityColors[entity.type] } }
                                    >
                                        <div>
                                            { entity.name }
                                        </div>
                                    </td>
                                </tr>
                            ))
                    ))
                }
                </tbody>
            </table>
        </div>

    )
}