import './Filter.css'
import { EntityType } from '../Types'
import 'react-color-palette/css'
import SelectColorModal from '../modals/SelectColorModal'
import { useState } from 'react'

export default function Filter(
    {
        entityColors,
        setEntityColors,
        visibleEntities,
        setVisibleEntities,
    }) {
    const [selectedEntityType, setSelectedEntityType] = useState(null)

    function toggleEntity(type) {
        setVisibleEntities(old => ({ ...old, [type]: !visibleEntities[type] }))
    }

    function changeColor(color, type) {
        if (color) setEntityColors(old => ({ ...old, [type]: color.hex }))
        setSelectedEntityType(null)
    }

    return (
        <div className="filter-container">
            { selectedEntityType !== null &&
                <SelectColorModal
                    show={ selectedEntityType !== null }
                    onHide={ (color) => changeColor(color, selectedEntityType) }
                    currentColor={ entityColors[selectedEntityType] }
                />
            }
            <div>
                <FilterViewItem
                    entityType={ EntityType.US }
                    entityColors={ entityColors }
                    setSelectedEntityType={ setSelectedEntityType }
                    visibleEntities={ visibleEntities }
                    toggleEntity={ toggleEntity }
                    title="Show User Stories"
                />
                <FilterViewItem
                    entityType={ EntityType.CLASS }
                    entityColors={ entityColors }
                    setSelectedEntityType={ setSelectedEntityType }
                    visibleEntities={ visibleEntities }
                    toggleEntity={ toggleEntity }
                    title="Show Classes"
                />
                <FilterViewItem
                    entityType={ EntityType.ACTOR }
                    entityColors={ entityColors }
                    setSelectedEntityType={ setSelectedEntityType }
                    visibleEntities={ visibleEntities }
                    toggleEntity={ toggleEntity }
                    title="Show Actors"
                />
                <FilterViewItem
                    entityType={ EntityType.ATTRIBUTE }
                    entityColors={ entityColors }
                    setSelectedEntityType={ setSelectedEntityType }
                    visibleEntities={ visibleEntities }
                    toggleEntity={ toggleEntity }
                    title="Show Attributes"
                />
                <FilterViewItem
                    entityType={ EntityType.RELATIONSHIP }
                    entityColors={ entityColors }
                    setSelectedEntityType={ setSelectedEntityType }
                    visibleEntities={ visibleEntities }
                    toggleEntity={ toggleEntity }
                    title="Show Relationships"
                />
            </div>
            <div className='survey-link'>
                <a href="https://forms.office.com/e/7J8U9vAZe9" target="_blank">Go to survey!</a>
            </div>
        </div>
    )
}

function FilterViewItem({ entityType, entityColors, setSelectedEntityType, visibleEntities, toggleEntity, title }) {
    return (
        <div className="filter-item">
            <span>{ title }</span>
            <div
                style={ { background: entityColors[entityType] } }
                onClick={ () => setSelectedEntityType(entityType) }
            ></div>
            <input
                type="checkbox"
                checked={ visibleEntities[entityType] }
                onChange={ () => toggleEntity(entityType) }
            />
        </div>
    )
}