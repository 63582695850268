const minValue = min => value => value && value < min ? `Must be at least ${min}` : undefined
const maxValue = max => value => value && value > max ? `Maximum ${max}` : undefined
const maxLength = max => value => value && value.length > max ? `Must be ${max} characters or less` : undefined
const minLength = min => value => value && value.length < min ? `Must be ${min} characters or more` : undefined

export const minValue0 = minValue(0);
export const maxValue10 = maxValue(10);
export const number = value => isNaN(value) ? 'Must be a number' : undefined;
export const maxLength100 = maxLength(100);
export const minLength3 = minLength(3);
export const minLength5 = minLength(5);
export const minLength8 = minLength(8);
export const required = value => value ? undefined : 'Required';

export const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address format: xx@xx.xx' : undefined;
export const passwordsMatch = (value, allValues) => value !== allValues.password ? 'Passwords don\'t match' : undefined;
export const password = value => value && /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/.test(value) ? undefined : 'Minimum 8 characters, at 1 uppercase letter, 1 number and 1 special character'