import React, { useState } from 'react'
import CardColumns from 'react-bootstrap/CardColumns'
import { useParams } from 'react-router-dom'

import './ProjectSettingsView.css'

import NavBar from '../../navigation/NavBar'
import GeneralSettingsCard from './projectSettingsCards/generalSettingsCard/GeneralSettingsCard'
import SprintSettingsCard from './projectSettingsCards/sprintSettingsCard/SprintSettingsCard'
import StateSettingsCard from './projectSettingsCards/stateSettingsCard/StateSettingsCard'
import UserRoleSettingsCard
    from 'components/project/projectSettings/projectSettingsCards/userRoleSettingsCard/UserRoleSettingsCard'
import ThemeSettingsCard
    from 'components/project/projectSettings/projectSettingsCards/themeSettingsCard/ThemeSettingsCard'
import CollaboratorSettingsCard
    from 'components/project/projectSettings/projectSettingsCards/collaboratorSettingsCard/CollaboratorSettingsCard'

function ProjectSettingsView(props) {
    const [isCreate, setIsCreate] = useState(props.isCreate)
    const { projectId } = useParams()
    let productBacklogIcon

    return (
        <>
            { (isCreate ? productBacklogIcon = false : productBacklogIcon = true) }
            <NavBar projects={ true } account={ true } productBacklog={ productBacklogIcon }/>
            <>
                <h1 id="projectSettingsTitle">{ isCreate ? 'Create new ' : 'Edit ' } project</h1>
                <CardColumns id="projectSettingsCardContainer">
                    <GeneralSettingsCard isCreate={ isCreate } projectId={ projectId }/>
                    { !isCreate &&
                        <>
                            <StateSettingsCard isCreate={ isCreate } projectId={ projectId }/>
                            <UserRoleSettingsCard isCreate={ isCreate } projectId={ projectId }/>
                            <SprintSettingsCard isCreate={ isCreate } projectId={ projectId }/>
                            <ThemeSettingsCard isCreate={ isCreate } projectId={ projectId }/>
                            <CollaboratorSettingsCard isCreate={ isCreate } projectId={ projectId }/>
                        </>
                    }
                </CardColumns>
            </>
        </>
    )
}

export default ProjectSettingsView
