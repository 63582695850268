import React from "react";
import {Field, Form, reduxForm, reset} from "redux-form";
import {Col} from "react-bootstrap";
import {Trash} from "react-bootstrap-icons";
import store from "store";

import "./SprintSettingsCard.css"

import FieldInput from "components/form/fieldInput/FieldInput";
import {maxLength100, minLength3, required} from "validation/InputValidation";
import Button from "components/form/buttons/SubmitButton";
import FieldDatePicker from "components/form/datePicker/FieldDatePicker";
import {PROJECT_SETTINGS_SPRINT_CREATE, PROJECT_SETTINGS_SPRINT_EDIT} from "constants/index"
import {addSprint, delSprint, updateSprint} from "model/sprints/sprints.actions";

function SprintForm({handleSubmit, mode, ...props}) {
    let projectId = props.projectId
    let sprintId = props.initialValues ? props.initialValues.sprintId : null
    return (
        <>
            <Form id="sprintSettings" onSubmit={handleSubmit}>
                <Field name="name"
                       label="Name"
                       type="text"
                       component={FieldInput}
                       placeholder="Name"
                       aria-label="Recipient's username"
                       aria-describedby="basic-addon2"
                       validate={[required, minLength3, maxLength100]}
                />
                <div className="row">
                    <Col>
                        <Field name="startDate"
                               type="text"
                               label="Start date"
                               component={FieldDatePicker}
                               placeholder="Start date"
                               aria-label="Recipient's username"
                               aria-describedby="basic-addon2"
                               validate={[required]}
                               minDate={new Date(1950, 1, 1)}
                        />
                    </Col>
                    <Col>
                        <Field name="endDate"
                               type="text"
                               label="End date"
                               component={FieldDatePicker}
                               placeholder="End date"
                               aria-label="Recipient's username"
                               aria-describedby="basic-addon2"
                               validate={[required]}
                               minDate={new Date(1950, 1, 1)}
                        />
                    </Col>
                </div>
                <Button
                    type="submit"
                    styleClass={mode === PROJECT_SETTINGS_SPRINT_CREATE ? "btn-primary-custom-btn-primary" : "btn-primary-custom-btn-submit"}
                    name={mode === PROJECT_SETTINGS_SPRINT_CREATE ? "Create sprint" : "Edit sprint"}
                />
            </Form>
            {mode === PROJECT_SETTINGS_SPRINT_EDIT &&
            <Button
                styleClass="btn-primary-custom-btn-naked deleteSprintButton"
                name={<Trash/>}
                onClick={() => onDelete(projectId, sprintId)}
            />
            }
        </>
    )
}

const onSubmit = (values, dispatch, props) => {
    let sprintId = props.initialValues ? props.initialValues.sprintId : null
    let projectId = props.projectId
    if (!values.sprintId) {
        store.dispatch(addSprint(projectId, values))
        store.dispatch(reset('newSprint'));
    } else {
        store.dispatch(updateSprint(projectId, sprintId, values))
    }
}

const onDelete = (projectId, sprintId) => {
    store.dispatch(delSprint(projectId, sprintId))
}

SprintForm = reduxForm({
    onSubmit
})(SprintForm)

export default SprintForm