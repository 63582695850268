export const GET_PROJECT_BY_ID_SUCCESS = 'GET_PROJECT_BY_ID_SUCCESS';
export const GET_PROJECT_BY_ID_PENDING = 'GET_PROJECT_BY_ID_PENDING';
export const GET_PROJECT_BY_ID_ERROR = 'GET_PROJECT_BY_ID_ERROR';

export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_PENDING = 'ADD_PROJECT_PENDING';
export const ADD_PROJECT_ERROR = 'ADD_PROJECT_ERROR';

export const GET_USERPROJECTS_SUCCESS = 'GET_USERPROJECTS_SUCCESS';
export const GET_USERPROJECTS_PENDING = 'GET_USERPROJECTS_PENDING';
export const GET_USERPROJECTS_ERROR = 'GET_USERPROJECTS_ERROR';

export const CLEAN_CURRENT_PROJECT = 'CLEAN_CURRENT_PROJECT';

export const DEL_PROJECT_ERROR = 'DEL_PROJECT_ERROR';
export const DEL_PROJECT_PENDING = 'DEL_PROJECT_PENDING';
export const DEL_PROJECT_SUCCESS = 'DEL_PROJECT_SUCCESS';