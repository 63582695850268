import React, {useEffect, useState} from "react";
import {Field, Form, reduxForm} from "redux-form";
import TypeHead from "components/form/asyncSearchTypehead/asyncSearchTypehead"
import {required} from "validation/InputValidation";
import Button from "components/form/buttons/SubmitButton";
import axios from "axios";
import constants from "constants/index";
import store from "store";
import {addCollaborator} from "model/collaborators/collaborators.actions";

function CollaboratorForm({handleSubmit, mode, ...props}) {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        axios.get(constants().apiUrl + '/user')
            .then(function (response) {
                setUsers(response.data.users.map(user => {
                    return {
                        label: user.firstname + ", " + user.lastname,
                        value: user.userId
                    }
                }))
            })
            .catch(function (error) {
                console.log("error trying get user:" + error)
            });
    }, [])

    return (
        <Form id="collaboratorSettings" onSubmit={handleSubmit}>
            <Field name="userId"
                   type="text"
                   label="Name"
                   items={users}
                   component={TypeHead}
                   placeholder="Name"
                   aria-label="Recipient's username"
                   aria-describedby="basic-addon2"
                   validate={[required]}
            />
            <Button
                type="submit"
                styleClass="btn-primary-custom-btn-primary"
                name="Add collaborator"
            />
        </Form>
    )
}

const onSubmit = (values, dispatch, props) => {
    let projectId = props.projectId
    store.dispatch(addCollaborator(projectId, values.userId))
}

CollaboratorForm = reduxForm({
    onSubmit
})(CollaboratorForm)

export default CollaboratorForm