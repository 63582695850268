import React from "react";
import './sprintView.css'
import './sprint.css'
import Sprint from "components/stateContainer/sprint/sprint";
import store from "../../../store";

let SprintView = ({sprints, states, ...props}) => {
    const state = store.getState();
    const filterCriteria = state.projects.current.filterCriteria
    let filterSprints = [...sprints]

    if(filterCriteria.sprints.length !== 0) {
        filterSprints = filterSprints.filter((item) => filterCriteria.sprints.includes(item.name))
    }

    return (
        <div className="sprintView">
            {
                filterSprints.map((item) => {
                    return <Sprint
                        key={item.sprintId}
                        projectId={props.projectId}
                        sprintName={item.name}
                        states={states}
                        sprintId={item.sprintId}
                        style={{order: item.sprintNumber}}
                        openModalEdit={props.openModalEdit}
                    />
                })
            }
        </div>
    )
}

export default SprintView;