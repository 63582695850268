import React, {useEffect} from "react"
import {connect} from "react-redux"
import ListGroup from "react-bootstrap/ListGroup"
import "components/project/projectSettings/projectSettingsCards/collaboratorSettingsCard/CollaboratorSettingsCard.css"
import FormCard from "components/form/formCard/FormCard"
import CollaboratorListItem
    from "components/project/projectSettings/projectSettingsCards/collaboratorSettingsCard/CollaboratorListItem"
import CollaboratorForm
    from "components/project/projectSettings/projectSettingsCards/collaboratorSettingsCard/CollaboratorForm"
import {cleanCollaborators, fetchCollaborators} from 'model/collaborators/collaborators.actions'

function CollaboratorSettingsCard({handleSubmit, mode, ...props}) {
    let projectId = props.projectId

    useEffect(() => {
        props.fetchCollaborators(projectId)
        return props.cleanCollaborators
    }, [])

    return (
        <FormCard title="collaborators" width="30vw" error={props.err} loading={props.loading}>
            <ListGroup class="list-group list-group-flush">
                {props.collaborators.map((collaborator) => {
                    return (
                        <ListGroup.Item key={collaborator.userId}>
                            <CollaboratorListItem user={collaborator} projectId={projectId}/>
                        </ListGroup.Item>
                    )
                })}
                <ListGroup.Item className="addCollaborator">
                    <CollaboratorForm form="addCollaborator" projectId={projectId}/>
                </ListGroup.Item>
            </ListGroup>
        </FormCard>
    )
}

const mapStateToProps = state => {
    return {
        collaborators: state.projects.current.collaborators.collaboratorsList,
        loading: state.projects.current.collaborators.pending,
        err: state.projects.current.collaborators.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCollaborators: (projectId) => dispatch(fetchCollaborators(projectId)),
        cleanCollaborators: () => dispatch(cleanCollaborators())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorSettingsCard)