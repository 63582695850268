import React, { useEffect } from 'react'
import NavBar from '../navigation/NavBar'
import './ProjectView.css'
import ProjectCard from './ProjectCard'
import AddProjectCard from './AddProjectCard'
import store from '../../store'
import { fetchUserProjects } from 'model/projects/project.actions'
import { connect } from 'react-redux'
import RandomColor from '../../helpers/randomColor'
import DateFormatter from '../../helpers/dateFormatter'
import { useLocation, useMatch } from 'react-router-dom'


const mapStateToProps = state => {
    return {
        initialValues: state.projects.myProjects,
    }
}

function ProjectView(props) {
    const { pathname } = useLocation()
    useEffect(() => {
        store.dispatch(fetchUserProjects())
    }, [])
    return (
        <>
            <NavBar account={ true } projects={ false } projectsSettings={ false }/>
            <div id={ 'titleDiv' }>
                <h1 id={ 'titlePage' }>Projects</h1>
            </div>
            <div className={ 'container-projectView' }>
                <div id={ 'invitedRow' }>
                    <p id={ 'titleRow' }>Invited</p>
                    <div id={ 'invitedElements' }>
                        {
                            props.initialValues.colProjects.map((item, index) => {
                                return <ProjectCard title={ item.name } path={ `${ pathname }/${ item.projectId }` }
                                                    description={ item.description }
                                                    color={ `${ RandomColor() }` }
                                                    startDate={ DateFormatter(item.startDate) }
                                                    typeOfProject={ 'invited' }/>
                            })
                        }
                    </div>
                </div>
                <div id={ 'ownRow' }>
                    <p id={ 'titleRow' }>Own</p>
                    <div id={ 'ownElements' }>
                        <AddProjectCard/>
                        {
                            props.initialValues.ownProjects.map((item, index) => {
                                return <ProjectCard title={ item.name } path={ `${ pathname }/${ item.projectId }` }
                                                    description={ item.description }
                                                    color={ `${ RandomColor() }` }
                                                    startDate={ DateFormatter(item.startDate) }
                                                    typeOfProject={ 'own' }/>
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(mapStateToProps)(ProjectView)
