import DeleteArtefactModal from './DeleteArtefactModal'
import AddClassModal from './AddClassModal'
import AddAttributeModal from './AddAttributeModal'
import AddRelationshipModal from './AddRelationshipModal'
import React from 'react'
import DeleteInstanceModal from './DeleteInstanceModal'
import UpdateInstanceModal from './UpdateInstanceModal'

export default function ModalGroup({
                                       classes,
                                       artefact,
                                       userStory,
                                       showDeleteInstanceModal,
                                       setShowDeleteInstanceModal,
                                       showUpdateInstanceModal,
                                       setShowUpdateInstanceModal,
                                       showDeleteModal,
                                       setShowDeleteModal,
                                       showAddClassModal,
                                       setShowAddClassModal,
                                       showAddAttributeModal,
                                       setShowAddAttributeModal,
                                       showAddRelationshipModal,
                                       setShowAddRelationshipModal,
                                   }) {
    function getClasses() {
        return classes.filter(c => {
            const ids = c.instances.map(i => i.mainArtefactId)
            return ids.includes(userStory.id)
        })
    }

    return (
        <>
            { showDeleteInstanceModal &&
                <DeleteInstanceModal
                    artefact={ artefact }
                    currentUserStory={ userStory }
                    show={ showDeleteInstanceModal }
                    onHide={ () => setShowDeleteInstanceModal(false) }
                />
            }
            { showUpdateInstanceModal &&
                <UpdateInstanceModal
                    artefact={ artefact }
                    show={ showUpdateInstanceModal }
                    onHide={ () => setShowUpdateInstanceModal(false) }
                />
            }
            { showDeleteModal &&
                <DeleteArtefactModal
                    artefactToDelete={ artefact }
                    show={ showDeleteModal }
                    onHide={ () => setShowDeleteModal(false) }
                />
            }
            { showAddClassModal &&
                <AddClassModal
                    userStory={ userStory }
                    show={ showAddClassModal }
                    onHide={ () => setShowAddClassModal(false) }
                />
            }
            { showAddAttributeModal &&
                <AddAttributeModal
                    currentClass={ artefact }
                    userStory={ userStory }
                    classes={ getClasses() }
                    show={ showAddAttributeModal }
                    onHide={ () => setShowAddAttributeModal(false) }
                />
            }
            { showAddRelationshipModal &&
                <AddRelationshipModal
                    currentClass={ artefact }
                    userStory={ userStory }
                    classes={ getClasses() }
                    show={ showAddRelationshipModal }
                    onHide={ () => setShowAddRelationshipModal(false) }
                />
            }
        </>
    )
}