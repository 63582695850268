function SortArray(array) {

    let uniqueNumbers = [...new Set(array)];

    uniqueNumbers.sort(function (a, b) {
        return a - b;
    });

    return uniqueNumbers
}

export default SortArray