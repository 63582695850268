import React, { useEffect, useState } from 'react'
import store from '../../../store'
import { fetchUserStories, setCurrentUserStory } from 'model/userStories/userStories.actions'
import NavBar from '../../navigation/NavBar'
import ProjectHeader from '../../navigation/ProjectHeader'
import ModalUS from '../../userStory/Modal'
import ProductBacklog from './ProductBacklog'
import SprintView from '../sprint/sprintView'
import Movement from '../../interaction/Movement'
import { cleanThemes, fetchThemes } from 'model/themes/themes.actions'
import { cleanUserRoles, fetchUserRoles } from 'model/userRoles/userRoles.actions'
import { cleanStates, fetchStates } from 'model/states/states.actions'
import { cleanSprints, fetchSprints } from 'model/sprints/sprints.actions'
import { cleanCollaborators, fetchCollaborators } from 'model/collaborators/collaborators.actions'
import { connect } from 'react-redux'
import Loader from '../../Loader'
import {
    cleanGeneralSettings,
    fetchGeneralSettings,
} from 'model/generalProjectSettings/generalProjectSettings.actions'
import WsServerConnector from 'helpers/wsServerConnector'
import constants from '../../../constants/index'
import Filtering from '../../filtering/Filtering.jsx'
import Sidebar from 'react-sidebar'
import './ProductBacklogView.css'
import { cleanFilterCriteria } from 'model/filtering/filterCriteria.action'
import { useLocation, useParams } from 'react-router-dom'

function ProductBacklogView(props) {
    const [state, setState] = useState({
        show: false,
        modalMode: 'create',
        sidebarOpen: false,
    })
    const [webSocket, setWebSocket] = useState(undefined)
    const params = useParams()
    const location = useLocation()

    function closeUserStoryModal() {
        setState({ ...state, show: false })
        store.dispatch(setCurrentUserStory({}))
    }

    function openUserStoryModalEditMode() {
        setState({ ...state, show: true, modalMode: 'edit' })
    }

    function openUserStoryModalCreateMode() {
        setState({ ...state, show: true, modalMode: 'create' })
    }

    useEffect(() => {
        componentDidMount()
        return componentWillUnmount
    }, [])

    return (
        <div id={ 'product-backlog-view' }>
            <Sidebar
                sidebar={ state.sidebarOpen === true &&
                    <Filtering toggleFilter={ () => setState({ sidebarOpen: !state.sidebarOpen }) }
                               projectId={ params.projectId }/> }
                open={ state.sidebarOpen }
                onSetOpen={ () => setState({ sidebarOpen: !state.sidebarOpen }) }
                styles={ {
                    sidebar: {
                        background: 'white',
                        zIndex: 10,
                        width: '30vw',
                        position: 'fixed',
                    },
                    overlay: {
                        zIndex: 9,
                    },
                } }
                pullRight={ true }
                shadow={ true }
                touch={ true }
            />

            { location.state.typeOfProject === 'invited' &&
                <NavBar account={ true }
                        projects={ true }
                        projectsSettings={ false }
                        filtering={ true }
                        openFilter={ () => setState({ sidebarOpen: !state.sidebarOpen }) }
                />
            }

            { (location.state.typeOfProject === 'own' || location.state === 'backToBacklog') &&
                <NavBar account={ true }
                        projects={ true }
                        projectsSettings={ true }
                        filtering={ true }
                        openFilter={ () => setState({ sidebarOpen: !state.sidebarOpen }) }/>
            }

            <ProjectHeader projectName={ props.generalSettings.name }
                           connected={ props.connectedToWsServer }
                           online={ props.amntPeopleOnline }/>

            <ModalUS show={ state.show }
                     onHide={ () => closeUserStoryModal() }
                     modalMode={ state.modalMode }/>

            { (props.userStories.pending || props.sprints.pending || props.states.pending) &&
                <div className="product-backlog-view">
                    <Loader/>
                </div> }
            { !(props.userStories.pending || props.states.pending || props.sprints.pending) &&
                <>
                    <ProductBacklog
                        openModalCreate={ openUserStoryModalCreateMode }
                        openModalEdit={ openUserStoryModalEditMode }
                        projectId={ params.projectId }
                    />
                    <div className={ 'allZones' }>
                        <SprintView
                            states={ props.states.statesList }
                            sprints={ props.sprints.sprintsList }
                            openModalEdit={ openUserStoryModalEditMode }
                            projectId={ params.projectId }
                        />
                    </div>
                    <Movement/>
                </>
            }
        </div>
    )

    /**
     * Fetch the userstories on mount of this component
     */
    function componentDidMount() {
        const {
            fetchUserStories,
            fetchThemes,
            fetchUserRoles,
            fetchStates,
            fetchSprints,
            fetchGeneralSettings,
            fetchCollaborators,
        } = props
        fetchUserStories(params.projectId)
        fetchThemes(params.projectId)
        fetchUserRoles(params.projectId)
        fetchStates(params.projectId)
        fetchSprints(params.projectId)
        fetchGeneralSettings(params.projectId)
        fetchCollaborators(params.projectId)

        setWebSocket(
            new WsServerConnector(
                constants().websocketUrl + '/?project=' + params.projectId,
                params.projectId,
            ),
        )
    }

    function componentWillUnmount() {
        const {
            cleanThemes,
            cleanUserRoles,
            cleanStates,
            cleanSprints,
            cleanGeneralSettings,
            cleanCollaborators,
            cleanFilterCriteria,
        } = props
        cleanThemes()
        cleanUserRoles()
        cleanStates()
        cleanSprints()
        cleanGeneralSettings()
        cleanCollaborators()
        cleanFilterCriteria()

        // webSocket.closeConnection() FIXME
    }
}

/**
 * connects the needed data from the store to the component
 * @param state
 * @returns {{userStories: []}}
 */
const mapStateToProps = state => {
    return {
        userStories: state.projects.current.userStories,
        themes: state.projects.current.themes,
        userRoles: state.projects.current.userRoles,
        states: state.projects.current.states,
        sprints: state.projects.current.sprints,
        generalSettings: state.projects.current.generalProjectSettings.generalProjectSettings,
        connectedToWsServer: state.websocket.connected,
        amntPeopleOnline: state.websocket.social.online,
    }
}

const mapDispatchToProps = (dispatch, _) => {
    return {
        fetchUserStories: (projectId) => dispatch(fetchUserStories(projectId)),
        fetchThemes: (projectId) => dispatch(fetchThemes(projectId)),
        fetchUserRoles: (projectId) => dispatch(fetchUserRoles(projectId)),
        fetchStates: (projectId) => dispatch(fetchStates(projectId)),
        fetchSprints: (projectId) => dispatch(fetchSprints(projectId)),
        fetchGeneralSettings: (projectId) => dispatch(fetchGeneralSettings(projectId)),
        fetchCollaborators: (projectId) => dispatch(fetchCollaborators(projectId)),
        cleanThemes: () => dispatch(cleanThemes()),
        cleanUserRoles: () => dispatch(cleanUserRoles()),
        cleanStates: () => dispatch(cleanStates()),
        cleanSprints: () => dispatch(cleanSprints()),
        cleanGeneralSettings: () => dispatch(cleanGeneralSettings()),
        cleanCollaborators: () => dispatch(cleanCollaborators()),
        cleanFilterCriteria: () => dispatch(cleanFilterCriteria()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBacklogView)
