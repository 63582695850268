import React, {useState} from "react";
import {Button, Card} from "react-bootstrap";
import {Dash, ListTask, Pencil, Plus, Trash} from "react-bootstrap-icons";
import './USCard.css'

const USCard = ({userStory, editLink, deleteLink, ...props}) => {

    const [size, setSize] = useState('mini')

    const editUserStory = () => {
        editLink(userStory)
    }

    const deleteUserStory = () => {
        deleteLink(userStory)
    }

    return (
        <Card draggable={true} className={size + " usCard"} id={userStory.userStoryId}
              style={{borderColor: '#' + userStory.color}}>
            <Card.Header className={'d-flex'} id={'headerStyle'}>
                <div className={'col m-0 p-0'}>
                    <div className="row firstRow">
                        <div className={"col-6 m-0 pl-1 idStyle"}>
                            {'ID ' + userStory.uniqueUsProjectId}
                        </div>
                        <div className={"col-6 m-0 p-0 fourButton"}>
                            {size === 'mini' ? (
                                <Button id={'buttonIconStyle'} onClick={() => setSize('middle')}>
                                    <Plus/>
                                </Button>) : (<Button id={'buttonIconStyle'} onClick={() => setSize('mini')}>
                                <Dash/>
                            </Button>)}
                            <Button id={'buttonIconStyle'}>
                                <ListTask/>
                            </Button>
                            <Button id={'buttonIconStyle'} onClick={editUserStory}>
                                <Pencil/>
                            </Button>
                            <Button id={'buttonIconStyle'} onClick={deleteUserStory}>
                                <Trash/>
                            </Button>
                        </div>
                    </div>
                    <div className="row descriptionStyle">
                        <div className={'col-12 m-0 pt-1 pl-1  '}>
                            {userStory.shortDescription}
                        </div>
                    </div>
                </div>
            </Card.Header>
            {size === 'middle' && <Card.Body id={'bodyStyle'}>
                <p><b>As a </b>{userStory.userRoleName},<b> I want </b>{userStory.goal},<b> so
                    that </b>{userStory.reason}</p>
            </Card.Body>}
        </Card>
    )
}

export default USCard;