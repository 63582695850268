import { ViewType } from '../Types'
import './ViewTabs.css'

export default function ViewTabs({ currentView, setCurrentView }) {
    return (
        <div className="tracing-view-tabs">
            <div
                className={ `tracing-view-tab-item ${ currentView === ViewType.GRAPH ? 'selected' : '' }` }
                onClick={ () => setCurrentView(ViewType.GRAPH) }
            >graph
            </div>
            <div
                className={ `tracing-view-tab-item ${ currentView === ViewType.CLASS_DIAGRAM ? 'selected' : '' }` }
                onClick={ () => setCurrentView(ViewType.CLASS_DIAGRAM) }
            >model
            </div>
            <div
                className={ `tracing-view-tab-item ${ currentView === ViewType.MATRIX ? 'selected' : '' }` }
                onClick={ () => setCurrentView(ViewType.MATRIX) }
            >matrix
            </div>
            <div
                className={ `tracing-view-tab-item ${ currentView === ViewType.LIST ? 'selected' : '' }` }
                onClick={ () => setCurrentView(ViewType.LIST) }
            >list
            </div>
        </div>
    )
}