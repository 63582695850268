import React from "react";
import {Col, ProgressBar} from "react-bootstrap";
import {connect} from "react-redux";
import store from 'store';
import {Field, reduxForm} from 'redux-form'

import './InputGroup.css'

import Button from "components/form/buttons/SubmitButton";
import {sendUpdateUserStory, sendUserStoryForm} from "model/UserStoryModal/userStoryForm/userStoryForm.actions";
import FieldInput from "components/form/fieldInput/FieldInput";
import SelectInput from "components/form/selectInput/SelectInput";
import {
    maxLength100,
    maxValue10,
    minLength3,
    minLength5,
    minValue0,
    number,
    required
} from "validation/InputValidation";

let closeModal;
let modalMode;

let USInputGroup = ({handleSubmit, mode, ...props}) => {
    let userRoles = props.userRoles.userRolesList.map((item) => {
            return {
                id: item.userRoleId,
                name: item.name
            }
        }
    );

    let themes = props.themes.themesList.map((item) => {
            return {
                id: item.themeId,
                name: item.name
            }
        }
    );

    closeModal = props.closeModal;
    modalMode = props.modalMode;

    return (
        <div className="us_inputGroup">
            <div className="row">
                <div className="col-sm">
                    <form onSubmit={handleSubmit}>
                        <Field name="shortDescription"
                               label="Short Description"
                               type="text"
                               as="textarea"
                               component={FieldInput}
                               placeholder="Short description"
                               aria-label="Recipient's username"
                               aria-describedby="basic-addon2"
                               validate={[required, minLength3, maxLength100]}
                        />

                        <div className="row">
                            <Col>
                                <Field name="priority"
                                       label="Priority"
                                       type="text"
                                       component={FieldInput}
                                       placeholder="Priority"
                                       aria-describedby="basic-addon2"
                                       validate={[required, number, maxValue10, minValue0]}
                                />
                            </Col>
                            <Col>
                                <Field name="estimate"
                                       label="Estimate"
                                       type="text"
                                       component={FieldInput}
                                       placeholder="Estimate"
                                       aria-describedby="basic-addon2"
                                       validate={[required, number, minValue0]}
                                />
                            </Col>
                        </div>

                        <Field
                            label="Theme"
                            name="themeId"
                            options={themes}
                            component={SelectInput}
                        />

                        <fieldset>
                            <legend>Story:</legend>
                            <Field
                                type="select"
                                placeholder="User Roles"
                                name="userRoleId"
                                options={userRoles}
                                label="User Role"
                                prepend="As a "
                                component={SelectInput}
                                validate={required}
                            />

                            <Field name="goal"
                                   label="Goal"
                                   placeholder="Goal"
                                   ariaLabel="With textarea"
                                   prepend="I want "
                                   component={FieldInput}
                                   validate={[required, minLength5, maxLength100]}
                            />

                            <Field name="reason"
                                   placeholder="Reason"
                                   ariaLabel="With textarea"
                                   as="textarea"
                                   prepend="so that"
                                   label="Reason"
                                   component={FieldInput}
                                   validate={[required, minLength5, maxLength100]}
                            />
                        </fieldset>

                        <Button
                            type="submit"
                            styleClass="btn-primary-custom-btn-primary"
                            name={modalMode === 'create' ? 'Create Userstory' : 'Edit Userstory'}
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}

/**
 * Calls redux action to send the data from the form received from redux-form
 * @param values
 * @param dispatch
 * @param props
 */
const onSubmit = (values, dispatch, props) => {
    console.log(values)
    if (modalMode === 'create') {
        store.dispatch(sendUserStoryForm(values, props.projectId))
    } else {
        store.dispatch(sendUpdateUserStory(values))
    }
    closeModal();
}

USInputGroup = reduxForm({
    form: 'userStory',
    onSubmit
})(USInputGroup)

/**
 * connects the needed data from the store to the component
 * @param state
 * @returns {{userStories: []}}
 */
const mapStateToProps = state => {
    return {
        themes: state.projects.current.themes,
        userRoles: state.projects.current.userRoles,
        userStoryForm: state.form.userStory,
        initialValues: state.projects.current.userStories.current
    }
}

export default connect(mapStateToProps)(USInputGroup)