import Modal from 'react-bootstrap/Modal'
import React, { useState } from 'react'
import { authHeader } from '../../../helpers/auth-header'
import axios from 'axios'
import constants from '../../../constants'
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

export default function DeleteArtefactModal({ artefactToDelete, show, onHide }) {
    const queryClient = useQueryClient()
    const [loading, setLoading] = useState(false)
    const { projectId } = useParams()


    async function onDelete() {
        const requestConfig = {
            headers: authHeader(),
        }
        setLoading(true)
        await axios.delete(`${ constants().apiUrl }/project/${ projectId }/conceptual-model/entity/${ artefactToDelete.id }`, requestConfig)
        await queryClient.invalidateQueries({ queryKey: [`tracing-data-${ projectId }`] })
        setLoading(false)
        onHide()
    }

    return (
        <Modal
            show={ show }
            onHide={ onHide }
            size={ 'sm' }
            aria-labelledby={ 'contained-modal-title-vcenter' }
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id={ 'contained-modal-title-vcenter' }>
                    Delete Artefact
                </Modal.Title>
            </Modal.Header>

            { artefactToDelete &&
                <Modal.Body className={ 'color-modal' }>
                    { loading ?
                        <div className="loading-container">
                            <Spinner animation="grow"/>
                        </div> :
                        <>
                            <div>Are you sure you want to delete the following artefact?</div>
                            <div>Type: { artefactToDelete.type.toLowerCase() }</div>
                            <div>Name: { artefactToDelete.name }</div>
                            <div>ID: { artefactToDelete.id }</div>
                            <div>Note: This action will also delete all sub-artefacts.</div>
                            <button onClick={ onDelete }>Yes</button>
                            <button onClick={ onHide }>Cancel</button>
                        </>
                    }
                </Modal.Body>
            }
        </Modal>
    )
}