import './GraphView.css'
import * as d3 from 'd3'
import React, { useEffect, useRef, useState } from 'react'
import { dragEnded, dragged, dragStarted, handleZoom } from '../../d3Util'
import { EntityType } from '../../Types'
import ModalGroup from '../../modals/ModalGroup'

function getLinks(entities) {
    const links = []
    links.push(...getUserStoryLinks(entities))
    links.push(...getAttributeLinks(entities))
    links.push(...getRelationshipLinks(entities))
    return links
}

function getUserStoryLinks(entities) {
    const links = []
    for (const entity of entities) {
        for (const instance of entity.instances) {
            links.push({
                source: `US-${ instance.mainArtefactId }`,
                target: entity.id,
                type: entity.type,
                name: entity.name,
            })
        }
    }
    return links
}

function getAttributeLinks(entities) {
    const attrs = entities.filter(e => e.type === 'ATTRIBUTE')
    return attrs
        .flatMap(entity => entity.mainEntities.map(me => ({
            source: me.mainEntityId,
            target: entity.id,
            type: entity.type,
            name: entity.name,
        })))
}

function getRelationshipLinks(entities) {
    return entities.filter(e => e.type === 'RELATIONSHIP')
        .flatMap(entity => entity.links.flatMap(link => ([
            {
                source: link.from,
                target: entity.id,
                type: entity.type,
                name: entity.name,
            },
            {
                source: link.to,
                target: entity.id,
                type: entity.type,
                name: entity.name,
            },
        ])))
}

function getNodes(entities, userStories) {
    return [
        ...entities,
        ...userStories.map(us => ({
            ...us,
            id: `US-${ us.id }`,
            usId: us.id,
            type: 'US',
        })),
    ]
}

function getVisibleNodes(nodes, visibleEntities, selectedUserStories) {
    const visibleNodes = nodes.filter(node => (visibleEntities[node.type]))
    if (selectedUserStories.length > 0) {
        return visibleNodes.filter(node => {
            if (node.type === 'US') {
                return selectedUserStories.includes(node.usId)
            } else {
                const ids = node.instances.map(i => i.mainArtefactId)
                return ids.filter(id => selectedUserStories.includes(id)).length > 0
            }
        })
    }
    return visibleNodes
}

function getVisibleLinks(links, nodes) {
    const ids = nodes.map(node => node.id)
    return links.filter(link => ids.includes(link.source.id) && ids.includes(link.target.id))
}

export default function GraphView({
                                      selectedUserStories,
                                      visibleEntities,
                                      entityColors,
                                      userStories,
                                      entities,
                                      setSelectedArtefact,
                                  }) {
    const svgRef = useRef()
    const width = '100%'
    const height = '100%'
    const viewBoxWidth = 1080
    const viewBoxHeight = 540
    const radius = 15
    const [allNodes, setAllNodes] = useState([])
    const [allLinks, setAllLinks] = useState([])

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showAddClassModal, setShowAddClassModal] = useState(false)
    const [showAddAttributeModal, setShowAddAttributeModal] = useState(false)
    const [showAddRelationshipModal, setShowAddRelationshipModal] = useState(false)
    const [modalArtefact, setModalArtefact] = useState(null)
    const [modalUserStory, setModalUserStory] = useState(null)

    useEffect(() => {
        const nodes = getNodes(entities, userStories)
        const links = getLinks(entities)
        setAllNodes(nodes)
        setAllLinks(links)
        buildGraph(nodes, links)
    }, [entities, userStories, entityColors])

    useEffect(() => {
        const nodes = getVisibleNodes(allNodes, visibleEntities, selectedUserStories)
        const links = getVisibleLinks(allLinks, nodes)
        if (nodes.length || links.length) buildGraph(nodes, links)
    }, [visibleEntities, selectedUserStories])

    function buildGraph(nodes, links) {
        function showTooltip(event, node) {
            const tooltip = d3.select('div.container')
                .append('div')
                .attr('id', 'tooltip')
                .style('left', (event.pageX + 10) + 'px')
                .style('top', (event.pageY - 15) + 'px')

            const usIds = node.instances ? node.instances.map(i => i.mainArtefactId) : [node.usId]

            tooltip.html(`
            <div>Name:</div>
            <div>${ node.name ? node.name : node.usId }</div>
            <div>Type:</div>
            <div style="text-transform: lowercase">${ node.type }</div>
            <div>User Stories:</div>
            <div>${
                userStories.filter(us => usIds.includes(us.id))
                    .map(us => us.uniqueUserStoryProjectId)
                    .reduce((a, b) => `${ a }, ${ b }`)
            }</div>
            `)

        }

        function hideTooltip() {
            d3.selectAll('div#tooltip')
                .remove()
        }

        function hideMenu() {
            d3.selectAll('div#menu')
                .remove()
        }

        function showMenu(event, node) {
            hideMenu()
            hideTooltip()
            const menu = d3.select('div.container')
                .append('div')
                .attr('id', 'menu')
                .style('left', (event.pageX + 10) + 'px')
                .style('top', (event.pageY - 15) + 'px')

            menu.append('div')
                .text('Details')
                .on('click', () => {
                    setSelectedArtefact(node)
                    hideMenu()
                })

            menu.append('div')
                .text('Delete node')
                .on('click', () => deleteNode(node))

            if (node.type === EntityType.CLASS || node.type === EntityType.ACTOR) {
                menu.append('div')
                    .text('Add attribute')
                    .on('click', () => addAttribute(node))
                menu.append('div')
                    .text('Add relationship')
                    .on('click', () => addRelationship(node))
            }

            if (node.type === EntityType.US) {
                menu.append('div')
                    .text('Add class')
                    .on('click', () => addClass(node))
            }
        }

        function deleteNode(node) {
            hideMenu()
            setModalArtefact(node)
            setShowDeleteModal(true)
        }

        function addClass(node) {
            hideMenu()
            setModalArtefact(node)
            setModalUserStory(node)
            setShowAddClassModal(true)
        }

        function addAttribute(node) {
            hideMenu()
            setModalArtefact(node)
            setModalUserStory(userStories.find(us => us.id === node.instances[0].mainArtefactId))
            setShowAddAttributeModal(true)
        }

        function addRelationship(node) {
            hideMenu()
            setModalArtefact(node)
            setModalUserStory(userStories.find(us => us.id === node.instances[0].mainArtefactId))
            setShowAddRelationshipModal(true)
        }

        function ticked() {
            link
                .attr('x1', d => d.source.x)
                .attr('y1', d => d.source.y)
                .attr('x2', d => d.target.x)
                .attr('y2', d => d.target.y)

            node
                .attr('cx', d => d.x)
                .attr('cy', d => d.y)

            text
                .attr('x', d => d.x)
                .attr('y', d => d.y)
        }

        function getNodeLabel(node) {
            switch (node.type) {
                case EntityType.US:
                    return `US${ node.uniqueUserStoryProjectId }`
                case EntityType.ACTOR:
                    return `A${ node.id }`
                case EntityType.CLASS:
                    return `C${ node.id }`
                case EntityType.RELATIONSHIP:
                    return `RS${ node.id }`
                case EntityType.ATTRIBUTE:
                    return `AT${ node.id }`
            }
        }

        const simulation = d3.forceSimulation(nodes)
            .force('link', d3.forceLink(links).id(d => d.id).distance(80))
            .force('charge', d3.forceManyBody())
            .force('center', d3.forceCenter(viewBoxWidth / 2, viewBoxHeight / 2))
            .on('tick', ticked)

        d3.select(svgRef.current)
            .selectAll('*')
            .remove()

        const svg = d3.select(svgRef.current)
            .attr('width', width)
            .attr('height', height)
            .attr('viewBox', [0, 0, viewBoxWidth, viewBoxHeight])
            .on('click', hideTooltip)
            .on('click', hideMenu)
            .on('contextmenu', (e) => e.preventDefault())
            .call(d3.zoom()
                .on('zoom', handleZoom))

        const link = svg.append('g')
            .attr('stroke', '#656565')
            .attr('stroke-opacity', 0.6)
            .selectAll('line')
            .data(links)
            .join('line')
            .attr('stroke-width', 1)

        const nodeGroup = svg.append('g')
            .selectAll('g')
            .data(nodes)
            .enter()
            .append('g')
            .on('mouseenter', (e, node) => showTooltip(e, node))
            .on('mouseout', () => hideTooltip())
            .on('contextmenu', (e, node) => showMenu(e, node))
            .on('click', (e, node) => setSelectedArtefact(node))
            .call(d3.drag()
                .on('start', (e) => dragStarted(e, simulation))
                .on('drag', dragged)
                .on('end', (e) => dragEnded(e, simulation)))

        const node = nodeGroup
            .append('circle')
            .attr('r', radius)
            .attr('fill', d => entityColors[d.type])

        const text = nodeGroup
            .append('text')
            .text(node => getNodeLabel(node))
            .attr('font-size', '8px')
            .attr('font-weight', 'lighter')
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'middle')
            .attr('stroke', '#000')
            .on('mouseenter', (e, node) => showTooltip(e, node))
            .on('mouseout', () => hideTooltip())
    }

    return (
        <>
            <ModalGroup
                classes={ entities.filter(e => e.type === 'ACTOR' || e.type === 'CLASS') }
                artefact={ modalArtefact }
                userStory={ modalUserStory }
                showDeleteModal={ showDeleteModal }
                setShowDeleteModal={ setShowDeleteModal }
                showAddClassModal={ showAddClassModal }
                setShowAddClassModal={ setShowAddClassModal }
                showAddAttributeModal={ showAddAttributeModal }
                setShowAddAttributeModal={ setShowAddAttributeModal }
                showAddRelationshipModal={ showAddRelationshipModal }
                setShowAddRelationshipModal={ setShowAddRelationshipModal }
            />
            <svg ref={ svgRef } className="tracing-graph"></svg>
        </>
    )
}
