import './Modal.css'
import Modal from 'react-bootstrap/Modal'
import { Spinner } from 'react-bootstrap'
import React, { useState } from 'react'
import SubArtefactPicker from './SubArtefactPicker'
import ClassDropdown from './ClassDropdown'
import { useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { authHeader } from '../../../helpers/auth-header'
import axios from 'axios'
import constants from '../../../constants'

export default function AddRelationshipModal({ currentClass, classes, userStory, show, onHide }) {
    const queryClient = useQueryClient()
    const [loading, setLoading] = useState(false)
    const [pickedSubArtefacts, setPickedSubArtefacts] = useState([])
    const [relationshipName, setRelationshipName] = useState('')
    const [fromClass, setFromClass] = useState(null)
    const [toClass, setToClass] = useState(null)
    const { projectId } = useParams()

    async function onSave() {
        const requestConfig = {
            headers: authHeader(),
        }
        const body = {
            name: relationshipName,
            mainArtefactId: userStory.id,
            fromEntityId: fromClass,
            toEntityId: toClass,
            position: pickedSubArtefacts.map(psa => psa.index),
        }
        setLoading(true)
        console.log({ body })
        await axios.post(`${ constants().apiUrl }/project/${ projectId }/conceptual-model/relationship-entity`, body, requestConfig)
        await queryClient.invalidateQueries({ queryKey: [`tracing-data-${ projectId }`] })
        setLoading(false)
        onHide()
    }

    return (
        <Modal
            show={ show }
            onHide={ onHide }
            size={ 'lg' }
            aria-labelledby={ 'contained-modal-title-vcenter' }
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id={ 'contained-modal-title-vcenter' }>
                    Add Relationship
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                { loading ?
                    <div className="loading-container">
                        <Spinner animation="grow"/>
                    </div> :
                    <>
                        <div className="modal-input-group">
                            <label htmlFor="relationship-name">Attribute name:</label>
                            <input
                                type="text"
                                name="relationship-name"
                                value={ relationshipName }
                                onChange={ (e) => setRelationshipName(e.target.value) }
                            />
                        </div>
                        <ClassDropdown
                            classes={ classes }
                            selectedClass={ fromClass }
                            setSelectedClass={ setFromClass }
                        />
                        <ClassDropdown
                            classes={ classes }
                            selectedClass={ toClass }
                            setSelectedClass={ setToClass }
                        />
                        <SubArtefactPicker
                            subArtefacts={ userStory.subArtefacts }
                            pickedSubArtefacts={ pickedSubArtefacts }
                            setPickedSubArtefacts={ setPickedSubArtefacts }
                        />
                        <div className="modal-button-row">
                            <button onClick={ onHide }>Cancel</button>
                            <button onClick={ onSave }>Save</button>
                        </div>
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}