import * as d3 from 'd3'

export function dragStarted(event, simulation) {
    if (!event.active) simulation.alphaTarget(0.3).restart()
    event.subject.fx = event.subject.x
    event.subject.fy = event.subject.y
}

export function dragged(event) {
    event.subject.fx = event.x
    event.subject.fy = event.y
}

export function dragEnded(event, simulation) {
    if (!event.active) simulation.alphaTarget(0)
    event.subject.fx = null
    event.subject.fy = null
}

export function handleZoom(event) {
    d3.selectAll('svg g line')
        .attr('transform', event.transform)
    d3.selectAll('svg g g')
        .attr('transform', event.transform)
}